import React, { useState } from 'react';
import { Steps, Card, Button } from 'antd';
import PropTypes from 'prop-types';
import GeneralInfo from './general';
import MedicalAntecedents from './medicalAntecedents';
import Allergies from './allergies';
import SurgicalAntecedents from './surgicalAntecedents';
import Treatments from './treatments';
import './styles.css';

const { Step } = Steps;

const MedicalRecord = ({
    onChange, values, handleFinish, readOnlyInputs,
}) => {
    const [current, setCurrent] = useState(0);

    const showStep = () => {
        switch (current) {
            case 0:
                return (
                    <GeneralInfo
                        onChange={onChange}
                        values={values}
                        readOnly={readOnlyInputs}
                    />
                );
            case 1:
                return <MedicalAntecedents onChange={onChange} values={values} />;
            case 2:
                return <Allergies onChange={onChange} values={values} />;
            case 3:
                return <SurgicalAntecedents onChange={onChange} values={values} />;
            case 4:
                return <Treatments onChange={onChange} values={values} />;
            default:
                return <div />;
        }
    };

    return (
        <div style={{ margin: '20px 50px' }}>
            <Card>
                <Steps
                    current={current}
                    onChange={setCurrent}
                    size="small"
                    style={{ marginBottom: 24 }}
                >
                    <Step title="Données Générales" />
                    <Step title="Antécédents Médicaux" />
                    <Step title="Allergies" />
                    <Step title="Antécédents Chirurgicaux" />
                    <Step title="Traitements" />

                </Steps>
                {showStep()}
                {handleFinish ? (
                    <Button
                        shape="round"
                        type="primary"
                        onClick={() => (current < 4 ? setCurrent(current + 1) : handleFinish())
                        }
                        className="center-button"
                    >
                        Suivant
                    </Button>
                ) : null}

            </Card>
        </div>
    );
};

MedicalRecord.propTypes = {
    onChange: PropTypes.func.isRequired,
    values: PropTypes.object.isRequired,
    handleFinish: PropTypes.func,
    readOnlyInputs: PropTypes.bool,
};

MedicalRecord.defaultProps = {
    handleFinish: null,
};

MedicalRecord.defaultProps = {
    readOnlyInputs: false,
};

export default MedicalRecord;
