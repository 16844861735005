const API_ENDPOINT = 'https://demo-api.katomi.co/v1'; // remote
// const API_ENDPOINT = 'http://localhost:8080/v1'; // local

const API_AUTH = `${API_ENDPOINT}/auth`;
const API_PATIENTS = `${API_ENDPOINT}/patients`;
const API_ADVERSE_EVENTS = `${API_ENDPOINT}/adverse-events`;
const API_STATS = `${API_ENDPOINT}/stats`;
const API_CHAT = `${API_ENDPOINT}/chat`;
const API_ASSIGNMENTS = `${API_ENDPOINT}/assignments/nurse`;
const API_CONSULTATIONS = `${API_ENDPOINT}/consultations`;
const API_PHOTOS = `${API_ENDPOINT}/photos`;

const API_REQ_HEADER = {
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
    },
};

export {
    API_ENDPOINT,
    API_AUTH,
    API_PATIENTS,
    API_ADVERSE_EVENTS,
    API_ASSIGNMENTS,
    API_CONSULTATIONS,
    API_PHOTOS,
    API_STATS,
    API_REQ_HEADER,
    API_CHAT,
};
