import { createStore, applyMiddleware, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import loading from './loading/reducer';
import auth from './auth/reducer';
import patients from './patients/reducer';
import consultations from './consultations/reducer';
import adverseEvents from './adverseEvents/reducer';
import stats from './stats/reducer';
import chat from './chat/reducer';

export default createStore(
    combineReducers({
        loading,
        auth,
        patients,
        consultations,
        adverseEvents,
        stats,
        chat,
    }),
    applyMiddleware(thunk),
);
