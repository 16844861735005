/* eslint-disable no-underscore-dangle */
/* eslint-disable no-param-reassign */
import React from 'react';
import {
    Card, message, Table, Button, Tag, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import AddAntecedent from './add';

const { Text } = Typography;

const radioSelection = [
    {
        label: 'Traitement en cours',
        value: 'Traitement en cours',
    },
    {
        label: 'Ancien traitement',
        value: 'Ancien traitement',
    },
];

const extraRadioInputs = {
    'Ancien traitement': {
        label: 'Date de fin du traitement',
        name: 'dateend',
        required: false,
        type: 'input',
    },
};

const addInputs = [
    {
        label: 'Traitement',
        name: 'treatment',
        required: true,
        type: 'input',
    },
    {
        label: 'Posologie',
        name: 'posology',
        required: true,
        type: 'input',
    },
    {
        label: 'Date de début du traitement',
        name: 'date',
        required: true,
        type: 'input',
    },
    {
        label: 'Observation',
        name: 'observation',
        required: false,
        type: 'input',
        placeholder: 'Observation',
    },
];


const reminder = [
    'IEC / ACE',
    'ARA II',
    'AINS',
    'Stéroïdes',
    'Immunosuppresseurs',
    'Anti-viraux',
    'Antibiotiques',
    'Antidiabétiques',
    'HCQ',
    'Anticoagulants',
    'Anti-H1',
];
const colors = [
    'magenta',
    'red',
    'volcano',
    'orange',
    'gold',
    'green',
    'cyan',
    'blue',
    'geekblue',
    'purple',
    'magenta',
];

const Treatments = ({ values, onChange }) => {
    const onDelete = (row) => {
        const newArr = values.treatments.filter(item => item.key !== row.key);
        onChange('treatments', newArr);
        message.success('Traitement supprimé avec succès');
    };
    const columns = [
        {
            title: <b> Nature </b>,
            dataIndex: 'nature',
            key: 'nature',
        },
        {
            title: <b> Type </b>,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: <b> Traitement </b>,
            dataIndex: 'treatment',
            key: 'treatment',
        },
        {
            title: <b> Posologie </b>,
            dataIndex: 'posology',
            key: 'posology',
        },
        {
            title: <b> Date de debut </b>,
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: <b> Date de fin </b>,
            dataIndex: 'dateend',
            key: 'dateend',
        },
        {
            title: <b> Observation </b>,
            dataIndex: 'observation',
            key: 'observation',
        },

        {
            title: <b> Actions </b>,
            key: 'action',
            render: a => (
                <Button
                    danger
                    shape="round"
                    onClick={() => {
                        onDelete(a);
                    }}
                >
                    Supprimer
                </Button>
            ),
        },
    ];

    const onAdd = (allergy) => {
        allergy.nature = 'Traitement';
        allergy.key = Math.random().toString();
        const newTreatments = [...values.treatments, allergy];
        onChange('treatments', newTreatments);
        message.success('Traitement ajouté avec succès');
    };

    return (
        <div>
            <Card title="Ajout d'un traitement" className="add-antecedent-card">
                <div className="dmi-treatment-reminder">
                    <Text type="secondary">Principales familles thérapeutiques</Text>
                    {reminder.map((elem, i) => (
                        <Tag color={colors[i]} style={{ margin: 5 }}>
                            {elem}
                        </Tag>
                    ))}
                </div>
                <AddAntecedent
                    onAdd={onAdd}
                    buttonLabel="Ajouter le traitement"
                    radioLabel="Catégorie du traitement"
                    radioSelection={radioSelection}
                    inputs={addInputs}
                    extraRadioInputs={extraRadioInputs}
                />
            </Card>

            <div>
                <Table dataSource={values.treatments} columns={columns} locale={{ emptyText: 'Aucun traitement' }} />
            </div>
        </div>
    );
};

Treatments.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default Treatments;
