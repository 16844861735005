/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
    Layout, Menu, Popover, Avatar,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { LogoutOutlined } from '@ant-design/icons';
import { disconnectAsync } from '../../redux/auth/actions';
import { getFullName, getUserInitials } from '../../helpers/functions';
import './styles.css';

const { Header } = Layout;

const AppHeader = ({ user, disconnectAsync }) => {
    const [openPop, setOpenPop] = useState(false);
    const PopoverContent = (
        <Menu>
            <Menu.Item onClick={disconnectAsync} icon={<LogoutOutlined />} key="0">
                Se déconnecter
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="header header-top">
            <div className="flex">
                <img
                    className="full-logo"
                    alt="demo-logo"
                    src="/demo-logo-only.png"
                />
            </div>
            <div style={{ color: 'rgb(196, 49, 63)', fontWeight: 800 }}>
                Demo
            </div>

            <div className="header-actions">
                <Popover
                    content={PopoverContent}
                    trigger="hover"
                    visible={openPop}
                    onVisibleChange={setOpenPop}
                >
                    <div
                        className="flex"
                        style={{ cursor: 'pointer', alignItems: 'center' }}
                    >
                        <Avatar size={30} style={{ marginRight: 5, backgroundColor: '#c4313f' }}>
                            {getUserInitials(user)}
                        </Avatar>
                        <span>{getFullName(user)}</span>
                    </div>
                </Popover>
            </div>
        </Header>
    );
};

AppHeader.propTypes = {
    user: PropTypes.object,
    disconnectAsync: PropTypes.func.isRequired,
};

AppHeader.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators({ disconnectAsync }, dispatch);

export default connect(stateToProps, dispatchToProps)(AppHeader);
