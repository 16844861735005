/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
    SET_EVENTS_LIST, SET_EVENT, PUSH_EVENTS_LIST, UPDATE_EVENT,
} from '../actions';

const initialState = {
    selectedEvent: null,
    eventsList: [],
};

const adverseEvents = (state = initialState, action) => {
    switch (action.type) {
        case SET_EVENTS_LIST:
            return { ...state, eventsList: action.payload };
        case PUSH_EVENTS_LIST:
            return { ...state, eventsList: [...state.eventsList, action.payload] };
        case UPDATE_EVENT:
            // eslint-disable-next-line no-case-declarations
            const eventsList = [];
            state.eventsList.map((ev) => {
                if (ev._id === action.payload._id) {
                    eventsList.push(action.payload);
                } else eventsList.push(ev);
            });
            return { ...state, eventsList };

        case SET_EVENT:
            return { ...state, selectedEvent: action.payload };
        default:
            return state;
    }
};

export default adverseEvents;
