import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Form, Input, Radio, InputNumber, Button, message,
} from 'antd';

const General = ({ values, onChange, readOnly }) => {
    const [openUpdate, setOpenUpdate] = useState(false);
    const [currentValues, setCurrentValues] = useState({});

    const handleChange = (key, value) => {
        if (openUpdate) {
            setCurrentValues({ ...currentValues, [key]: value });
        } else {
            onChange(key, value);
        }
    };

    const handleUpdate = () => {
        const keys = Object.keys(currentValues);
        const tobbaco = {};
        // eslint-disable-next-line array-callback-return
        keys.map((key) => {
            if (
                key !== 'smoker'
        && key !== 'smokerSince'
        && key !== 'numberOfCigarettes'
            ) {
                onChange(key, currentValues[key]);
            } else {
                tobbaco[key] = currentValues[key];
            }
        });
        if (Object.keys(tobbaco).length) {
            onChange('tobbaco', tobbaco);
        }
        message.success('Modification effectuée avec succès');
        setOpenUpdate(false);
    };

    return (
        <Form style={{ marginTop: 30 }}>
            <div className="flex" style={{ justifyContent: 'space-around' }}>
                <div>
                    <Form.Item label="Poids (Kg)" style={{ marginRight: 15 }}>
                        <Input
                            readOnly={readOnly && !openUpdate}
                            value={
                                openUpdate && currentValues.weight
                                    ? currentValues.weight
                                    : values.weight
                            }
                            onChange={e => handleChange('weight', e.target.value)}
                            style={{ width: 'min-content' }}
                        />
                    </Form.Item>
                    <Form.Item label="Taille (Cm)">
                        <Input
                            readOnly={readOnly && !openUpdate}
                            value={
                                openUpdate && currentValues.height
                                    ? currentValues.height
                                    : values.height
                            }
                            onChange={e => handleChange('height', e.target.value)}
                            style={{ width: 'min-content' }}
                        />
                    </Form.Item>
                </div>
                <div>
                    <Form.Item label="Fumeur">
                        <Radio.Group
                            disabled={readOnly && !openUpdate}
                            onChange={e => handleChange('smoker', e.target.value)}
                            value={
                                openUpdate && currentValues.smoker
                                    ? currentValues.smoker
                                    : values.smoker
                            }
                        >
                            <Radio value="Oui">Oui</Radio>
                            <Radio value="Non">Non</Radio>
                        </Radio.Group>
                    </Form.Item>
                    {values.smoker || currentValues.smoker === 'Oui' ? (
                        <div style={{ marginTop: 14 }}>
                            <Form.Item label="Nombre de cigarettes par jour">
                                <InputNumber
                                    readOnly={readOnly && !openUpdate}
                                    onChange={(val) => {
                                        handleChange('numberOfCigarettes', val);
                                    }}
                                    value={
                                        openUpdate && currentValues.numberOfCigarettes
                                            ? currentValues.numberOfCigarettes
                                            : values.numberOfCigarettes
                                    }
                                    min={1}
                                    style={{ width: 100, marginLeft: 10 }}
                                />
                            </Form.Item>
                            <Form.Item label="Depuis quand? (années)">
                                <Input
                                    readOnly={readOnly && !openUpdate}
                                    value={
                                        openUpdate && currentValues.smokerSince
                                            ? currentValues.smokerSince
                                            : values.smokerSince
                                    }
                                    onChange={(e) => {
                                        handleChange('smokerSince', e.target.value);
                                    }}
                                    style={{ width: 100, marginLeft: 10 }}
                                />
                            </Form.Item>
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="flex" style={{ justifyContent: 'center' }}>
                {readOnly && !openUpdate ? (
                    <Button onClick={() => setOpenUpdate(true)} type="primary" ghost>
                        Modifier
                    </Button>
                ) : null}
                {readOnly && openUpdate ? (
                    <div>
                        <Button
                            style={{ marginRight: 10 }}
                            onClick={() => setOpenUpdate(false)}
                        >
                            Annuler
                        </Button>
                        <Button type="primary" ghost onClick={() => handleUpdate()}>
                            Valider
                        </Button>
                    </div>
                ) : null}
            </div>
        </Form>
    );
};

General.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    readOnly: PropTypes.bool,
};

General.defaultProps = {
    readOnly: false,
};

export default General;
