import React, { useState } from 'react';
import {
    Form, InputNumber, Radio, Button, Input,
} from 'antd';
import PropTypes from 'prop-types';
import FORM_VALIDATORS from '../../../helpers/formRules';

const ClinicalExamination = ({ handleNext }) => {
    // const [PCRValue, setpcrValue] = useState();
    // const [SLGValue, setSLGValue] = useState();
    const [radioOxygenType, setRadioOxygenType] = useState();
    const [form] = Form.useForm();

    const onRadioWithExtraFieldChange = (field, value) => {
        form.setFieldsValue({ [field]: value });
        if (field === 'oxygenType') {
            setRadioOxygenType(value);
        }
        // else if (field === 'testPCR') {
        //     setpcrValue(value);
        // } else {
        //     setSLGValue(value);
        // }
    };

    const onFinish = (values) => {
        const body = {
            ...values,
            // testPCR: {
            //     value: values.testPCR === 'Oui',
            //     result: values.resultPCR,
            //     date: values.datePCR,
            // },
            // testSLG: {
            //     value: values.testSLG === 'Oui',
            //     result: values.resultSLG,
            //     date: values.dateSLG,
            // },
            bloodPressure: {
                systolique: values.systolique,
                diastolique: values.diastolique,

            },
            oxygenSaturation: {
                value: values.oxygenSaturation,
                oxygenDebit: values.oxygenDebit,
                oxygenType: values.oxygenType,
            },
        };
        handleNext('clinicalExamination', body);
    };


    return (
        <Form
            form={form}
            className="clinical-examination-form"
            onFinish={onFinish}
        >
            <Form.Item label="Pression Artérielle (mmHg)">
                <Input.Group compact className="flex">
                    <Form.Item
                        noStyle
                        name="systolique"
                        rules={[FORM_VALIDATORS.required]}
                    >
                        <InputNumber placeholder="TA systolique" />
                    </Form.Item>
                    <span style={{ margin: '0 8px' }}> / </span>
                    <Form.Item
                        noStyle
                        name="diastolique"
                        rules={[FORM_VALIDATORS.required]}
                    >
                        <InputNumber placeholder="TA diastolique" />
                    </Form.Item>
                </Input.Group>
            </Form.Item>


            <Form.Item
                name="heartRate"
                rules={[FORM_VALIDATORS.required]}
                label="Fréquence cardiaque (BPM)"
            >
                <InputNumber
                />
            </Form.Item>
            <Form.Item
                name="respiratoryRate"
                rules={[FORM_VALIDATORS.required]}
                label="Fréquence respiratoire (CPM)"
            >
                <InputNumber />
            </Form.Item>

            <Form.Item
                name="oxygenSaturation"
                rules={[FORM_VALIDATORS.required]}
                label="Sat O2 (%)"
            >
                <InputNumber />
            </Form.Item>
            <div style={{ marginLeft: 24 }}>
                <Form.Item
                    name="oxygenType"
                    rules={[FORM_VALIDATORS.required]}
                    onChange={(e) => { onRadioWithExtraFieldChange('oxygenType', e.target.value); }}
                    value={radioOxygenType}

                >
                    <Radio.Group
                    >
                        <Radio value="air ambiant">Air ambiant</Radio>
                        <Radio value="Oxygénothérapie">Oxygénothérapie</Radio>
                    </Radio.Group>
                </Form.Item>
                {radioOxygenType === 'Oxygénothérapie' ? (
                    <Form.Item
                        name="oxygenDebit"
                        rules={[FORM_VALIDATORS.required]}
                        label="Débit d'oxygéne"
                    >
                        <InputNumber />
                    </Form.Item>

                ) : null}
            </div>
            <Form.Item
                name="bodyTemperature"
                rules={[FORM_VALIDATORS.required]}
                label="Température (°C)"
            >
                <InputNumber />
            </Form.Item>
            {/* <Form.Item
                label="Test PCR"
                name="testPCR"
                rules={[FORM_VALIDATORS.required]}
            >
                <Radio.Group
                    onChange={e => onRadioWithExtraFieldChange('testPCR', e.target.value)}
                    value={PCRValue}
                >
                    <Radio value="Oui">Oui</Radio>
                    <Radio value="Non">Non</Radio>
                </Radio.Group>
                {PCRValue === 'Oui' ? (
                    <div style={{ marginLeft: 10, maxWidth: '50%', marginTop: 24 }}>
                        <Form.Item label="Resultat"
                            name="resultPCR"
                            rules={[FORM_VALIDATORS.required]}>
                            <Radio.Group>
                                <Radio value="Positif">Positif</Radio>
                                <Radio value="Négatif">Négatif</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Date" name="datePCR" rules={[FORM_VALIDATORS.required]}>
                            <Input />
                        </Form.Item>
                    </div>
                ) : null}
            </Form.Item> */}


            {/* <Form.Item
                label="Test Sérologique"
                name="testSLG"
                rules={[FORM_VALIDATORS.required]}
            >
                <Radio.Group
                    onChange={e => onRadioWithExtraFieldChange('testSLG', e.target.value)}
                    value={SLGValue}
                >
                    <Radio value="Oui">Oui</Radio>
                    <Radio value="Non">Non</Radio>
                </Radio.Group>
                {SLGValue === 'Oui' ? (
                    <div style={{ marginLeft: 10, maxWidth: '50%', marginTop: 24 }}>
                        <Form.Item label="Resultat"
                            name="resultSLG"
                            rules={[FORM_VALIDATORS.required]}>
                            <Radio.Group>
                                <Radio value="Positif">Positif</Radio>
                                <Radio value="Négatif">Négatif</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item label="Date" name="dateSLG" rules={[FORM_VALIDATORS.required]}>
                            <Input />
                        </Form.Item>
                    </div>
                ) : null}
            </Form.Item> */}

            <Form.Item
                name="observation"
                label="Observation"
            >
                <Input.TextArea rows={2} />
            </Form.Item>

            <Form.Item>
                <Button className="center-button" htmlType="submit" shape="round" type="primary" size="large">
                    Suivant
                </Button>
            </Form.Item>
        </Form>
    );
};

ClinicalExamination.propTypes = {
    handleNext: PropTypes.func.isRequired,
};

export default ClinicalExamination;
