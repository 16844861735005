/* eslint-disable no-param-reassign */
import React from 'react';
import {
    Card, Button, Table, message,
} from 'antd';
import PropTypes from 'prop-types';
import AddAntecedent from './add';

const addInputs = [
    {
        label: "Type d'intervention",
        name: 'type',
        required: true,
        type: 'input',
    },
    {
        label: "Date d'intervention",
        name: 'date',
        required: true,
        type: 'input',
    },
    {
        label: 'Observation',
        name: 'observation',
        required: false,
        type: 'input',
    },
];

const SurgicalAntecedents = ({ values, onChange }) => {
    const onDelete = (row) => {
        const newArr = values.surgicalAntecedents.filter(item => item.key !== row.key);
        onChange('surgicalAntecedents', newArr);
        message.success('Antécédent supprimé avec succès');
    };
    const columns = [
        {
            title: <b>  Nature </b>,
            dataIndex: 'nature',
            key: 'nature',
        },
        {
            title: <b> Type </b>,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: <b> Date </b>,
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: <b> Observation </b>,
            dataIndex: 'observation',
            key: 'observation',
        },
        {
            title: <b> Actions </b>,
            key: 'action',
            render: a => (
                <Button
                    danger
                    shape="round"
                    onClick={() => {
                        onDelete(a);
                    }}
                >
                    Supprimer
                </Button>
            ),
        },
    ];

    const onAdd = (antecedent) => {
        antecedent.nature = 'Antécédent chirurgicale';
        antecedent.key = Math.random().toString();
        const newAntecedents = [...values.surgicalAntecedents, antecedent];
        onChange('surgicalAntecedents', newAntecedents);
        message.success('Antécédent ajouté avec succès');
    };


    return (
        <div>
            <Card title="Ajout d'un antécédent" className="add-antecedent-card">
                <AddAntecedent
                    onAdd={onAdd}
                    buttonLabel="Ajouter l'antécédent"
                    radioLabel="Type d'intervention"
                    radioSelection={[]}
                    inputs={addInputs}
                />
            </Card>

            <div>

                <Table dataSource={values.surgicalAntecedents} columns={columns} locale={{ emptyText: 'Aucun antécédent' }} />
            </div>
        </div>
    );
};


SurgicalAntecedents.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default SurgicalAntecedents;
