import axios from 'axios';
import { API_STATS, API_REQ_HEADER } from './config';

const getDashboardStats = async () => {
    const res = await axios.get(`${API_STATS}/`, API_REQ_HEADER);
    return res.data;
};

const getPatient = async (id) => {
    const res = await axios.get(`${API_STATS}/${id}`, API_REQ_HEADER);
    return res.data;
};


const getPatientConsultations = async (id) => {
    const res = await axios.get(`${API_STATS}/consultations/${id}`, API_REQ_HEADER);
    return res.data;
};


export {
    getDashboardStats, getPatient, getPatientConsultations,
};
