import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    Card, Form, Input, Checkbox, Button,
} from 'antd';
import {
    LoginOutlined, UserOutlined, LockOutlined,
} from '@ant-design/icons';
import PropTypes from 'prop-types';
import { loginAsync } from '../../redux/auth/actions';


import FORM_VALIDATORS from '../../helpers/formRules';

const LoginForm = ({ loginAsync }) => {
    const onFinish = (values) => {
        loginAsync(values);
    };

    return (
        <Card
            className="login-card"
            title={(
                <div>
                    <LoginOutlined className="left-icon" />
                    Connexion
                </div>
            )}
        >
            <Form
                size="large"
                name="login-form"
                className="login-form"
                initialValues={{ remember: true }}
                onFinish={onFinish}
            >
                <Form.Item
                    name="email"
                    rules={[FORM_VALIDATORS.required, FORM_VALIDATORS.email]}
                >
                    <Input
                        prefix={<UserOutlined className="site-form-item-icon" />}
                        placeholder="Email"
                    />
                </Form.Item>
                <Form.Item name="password" rules={[FORM_VALIDATORS.required]}>
                    <Input
                        prefix={<LockOutlined className="site-form-item-icon" />}
                        type="password"
                        placeholder="Mot de passe"
                    />
                </Form.Item>
                <Form.Item>
                    <Form.Item name="remember" valuePropName="checked" noStyle>
                        <Checkbox>Se souvenir de moi</Checkbox>
                    </Form.Item>
                </Form.Item>
                <Form.Item>
                    <Button
                        block
                        shape="round"
                        type="primary"
                        htmlType="submit"
                        style={{ marginTop: 5 }}
                    >
                        Se connecter
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};


LoginForm.propTypes = {
    loginAsync: PropTypes.func.isRequired,
};


const dispatchToProps = dispatch => bindActionCreators(
    {
        loginAsync,
    },
    dispatch,
);


export default connect(null, dispatchToProps)(LoginForm);
