/* eslint-disable no-param-reassign */
import React from 'react';
import {
    Card, Button, Table, message,
} from 'antd';
import PropTypes from 'prop-types';
import AddAntecedent from './add';

const radioSelection = [
    {
        label: 'Générale',
        value: 'Générale',
    },
    {
        label: 'Médicamenteuse',
        value: 'Médicamenteuse',
    },

];

const addInputs = [
    {
        label: 'Allergène',
        name: 'allergene',
        required: true,
        type: 'input',
    },
    {
        label: "Date de début de l'allergie",
        name: 'date',
        required: true,
        type: 'input',
    },
    {
        label: 'Observation',
        name: 'observation',
        required: false,
        type: 'input',
    },
];


const Allergies = ({ values, onChange }) => {
    const onDelete = (row) => {
        const newArr = values.allergies.filter(item => item.key !== row.key);
        onChange('allergies', newArr);
        message.success('Allergie supprimée avec succès');
    };

    const columns = [
        {
            title: <b>  Nature </b>,
            dataIndex: 'nature',
            key: 'nature',
        },
        {
            title: <b> Type </b>,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: <b> Allergène </b>,
            dataIndex: 'allergene',
            key: 'allergene',
        },
        {
            title: <b> Date </b>,
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: <b> Observation </b>,
            dataIndex: 'observation',
            key: 'observation',
        },
        {
            title: <b> Actions </b>,
            key: 'action',
            render: a => (
                <Button
                    danger
                    shape="round"
                    onClick={() => {
                        onDelete(a);
                    }}
                >
                    Supprimer
                </Button>
            ),
        },
    ];

    const onAdd = (allergy) => {
        allergy.nature = 'Allergie';
        allergy.key = Math.random().toString();
        const newAllergies = [...values.allergies, allergy];
        onChange('allergies', newAllergies);
        message.success('Allergie ajoutée avec succès');
    };


    return (
        <div>
            <Card title="Ajout d'une allergie" className="add-antecedent-card">
                <AddAntecedent
                    onAdd={onAdd}
                    buttonLabel="Ajouter l'allergie"
                    radioLabel="Type d'allergie"
                    radioSelection={radioSelection}
                    inputs={addInputs}
                />
            </Card>

            <div>

                <Table dataSource={values.allergies} columns={columns} locale={{ emptyText: 'Aucune allergie' }} />

            </div>
        </div>
    );
};


Allergies.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};


export default Allergies;
