import { SET_CONSULTATION_LIST } from '../actions';

const initialState = {
    consultationList: [],
};

const consultations = (state = initialState, action) => {
    switch (action.type) {
        case SET_CONSULTATION_LIST:
            return { consultationList: action.payload };
        default:
            return state;
    }
};

export default consultations;
