import axios from 'axios';
import { API_ADVERSE_EVENTS, API_REQ_HEADER } from './config';

const getAll = async () => {
    const res = await axios.get(API_ADVERSE_EVENTS, API_REQ_HEADER);
    return res.data;
};

const getByPatient = async (id) => {
    const res = await axios.post(`${API_ADVERSE_EVENTS}/pt/${id}`, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_ADVERSE_EVENTS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const create = async (data) => {
    const res = await axios.post(`${API_ADVERSE_EVENTS}/`, data, API_REQ_HEADER);
    return res.data;
};
const update = async (id, data) => {
    const res = await axios.put(`${API_ADVERSE_EVENTS}/${id}`, data, API_REQ_HEADER);
    return res.data;
};

export {
    create, getById, update, getAll, getByPatient,
};
