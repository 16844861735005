import { DateTime } from 'luxon';

export function getBase64(file) {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
}

// eslint-disable-next-line max-len
export const isConfigComplete = ({ signature, pricing, stripeAccount }) => (signature && pricing && stripeAccount);

export const getFullName = user => (`${user.lastName} ${user.firstName}`);
export const getUserInitials = user => (`${user.lastName[0]} ${user.firstName[0]}`);
export const getFullNameInversed = user => `${user.firstName} ${user.lastName}`;

export const formatDate = date => (date ? DateTime.fromJSDate(date)
    .setLocale('fr')
    .toLocaleString(DateTime.DATE_MED) : '');


export const formatDateFromIso = date => (date ? DateTime.fromISO(date)
    .setLocale('fr')
    .toLocaleString(DateTime.DATE_MED) : '');


export const formatDateTimeFromIso = date => (date ? DateTime.fromISO(date)
    .setLocale('fr')
    .toLocaleString(DateTime.DATETIME_MED) : '');


export const dateTimeFromJSDate = date => (date ? DateTime.fromJSDate(date)
    .setLocale('fr')
    .toLocaleString(DateTime.DATETIME_MED) : '');


export const dateTimeFromTimestamp = date => (date ? DateTime.fromMillis(date)
    .setLocale('fr')
    .toLocaleString(DateTime.DATETIME_MED) : '');
