/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import { Steps } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import Documents from '../medicalRecord/documents';

const { Step } = Steps;

const AddConsultation = ({ selectedPatient }) => {
    const [current, setCurrent] = useState(0);


    const showStep = () => {
        switch (current) {
            case 0:
                return <Documents key="bio" category="bio" patient={selectedPatient} />;
            case 1:
                return <Documents key="radio" category="radio" patient={selectedPatient} />;
            case 2:
                return <Documents key="autres" category="autres" patient={selectedPatient} />;
            default:
                return <div />;
        }
    };

    return (
        <div className="flex-column" style={{ margin: '50px 30px 30px' }}>
            <Steps current={current} onChange={setCurrent}>
                <Step title="Examens biologiques" />
                <Step title="Examens radiologiques" />
                <Step title="Autres examens" />
            </Steps>
            <div style={{ margin: '24px auto' }}>{showStep()}</div>
        </div>
    );
};

AddConsultation.propTypes = {
    selectedPatient: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    selectedPatient: state.patients.selectedPatient,
});


export default connect(stateToProps)(AddConsultation);
