/* eslint-disable no-param-reassign */
import React from 'react';
import {
    Card, Button, Table, message,
} from 'antd';
import PropTypes from 'prop-types';
import AddAntecedent from './add';

const radioSelection = [
    { label: 'Cardiopathie chronique', value: 'Cardiopathie chronique' },
    {
        label: 'Maladie respiratoire chronique',
        value: 'Maladie respiratoire chronique',
    },
    { label: 'Hypertension', value: 'Hypertension' },
    { label: 'Asthme', value: 'Asthme' },
    {
        label: 'Insuffisance rénale chronique',
        value: 'Insuffisance rénale chronique',
    },
    { label: 'Asplénie', value: 'Asplénie' },
    { label: 'Greffé', value: 'Greffé' },
    { label: 'Dialysé', value: 'Dialysé' },
    { label: 'Tumeur', value: 'Tumeur' },
    { label: 'Insuffisance hépatique', value: 'Insuffisance hépatique' },
    { label: 'Trouble neurologique', value: 'Trouble neurologique' },
    { label: 'Pathologie hématologique', value: 'Pathologie hématologique' },
    { label: 'Affection rhumatismale', value: 'Affection rhumatismale' },
    { label: 'Maladie auto-immune', value: 'Maladie auto-immune' },
    {
        label: 'Pathologie infectieuse chronique',
        value: 'Pathologie infectieuse chronique',
    },
    { label: 'Diabète', value: 'Diabète' },
    { label: 'Cancer', value: 'Cancer' },
    { label: 'Autre', value: 'other' },
];

const pathologyInputField = {
    type: 'input',
    label: 'Pathologie',
    name: 'pathology',
};

const extraRadioInputs = {
    other: {
        type: 'input',
        label: 'Autre',
        name: 'type',
    },
    'Cardiopathie chronique': pathologyInputField,
    'Trouble neurologique': pathologyInputField,
    'Pathologie hématologique': pathologyInputField,
    'Affection rhumatismale': pathologyInputField,
    'Maladie auto-immune': pathologyInputField,
    'Pathologie infectieuse chronique': pathologyInputField,
    'Maladie respiratoire chronique': pathologyInputField,
    Diabète: pathologyInputField,
    Cancer: pathologyInputField,
};

const addInputs = [
    {
        label: 'Date de début de la maladie (jj/mm/aaaa)',
        name: 'date',
        required: true,
        type: 'input',
    },
    {
        label: 'Observation',
        name: 'observation',
        required: false,
        type: 'input',
    },
];

const MedicalAntecedents = ({ values, onChange }) => {
    const onDelete = (row) => {
        const newArr = values.medicalAntecedents.filter(item => item.key !== row.key);
        onChange('medicalAntecedents', newArr);
        message.success('Antécédent supprimé avec succès');
    };

    const columns = [
        {
            title: <b> Nature </b>,
            dataIndex: 'nature',
            key: 'nature',
        },
        {
            title: <b> Type </b>,
            dataIndex: 'type',
            key: 'type',
        },
        {
            title: <b> Pathologie </b>,
            dataIndex: 'pathology',
            key: 'pathology',
        },
        {
            title: <b> Date </b>,
            dataIndex: 'date',
            key: 'date',
        },
        {
            title: <b> Observation </b>,
            dataIndex: 'observation',
            key: 'observation',
        },
        {
            title: <b> Actions </b>,
            key: 'action',
            render: a => (
                <Button
                    danger
                    shape="round"
                    onClick={() => {
                        onDelete(a);
                    }}
                >
                    Supprimer
                </Button>
            ),
        },
    ];

    const onAdd = (antecedent) => {
        antecedent.nature = 'Antécédent médical';
        antecedent.key = Math.random().toString();
        const newAntecedents = [...values.medicalAntecedents, antecedent];
        onChange('medicalAntecedents', newAntecedents);
        message.success('Antécédent ajouté avec succès');
    };


    return (
        <div>
            <div className="flex" style={{ justifyContent: 'space-between' }}>
                <Card title="Ajout d'un antécédent médical" className="add-antecedent-card">
                    <AddAntecedent
                        onAdd={onAdd}
                        buttonLabel="Ajouter l'antécédent"
                        radioLabel="Type d'antécédent"
                        radioSelection={radioSelection}
                        inputs={addInputs}
                        extraRadioInputs={extraRadioInputs}
                    />
                </Card>

            </div>

            <div>
                <Table dataSource={values.medicalAntecedents} columns={columns} locale={{ emptyText: 'Aucun antécédent' }} />
            </div>
        </div>
    );
};

MedicalAntecedents.propTypes = {
    values: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default MedicalAntecedents;
