import React from 'react';
import {
    Card, Avatar, Form, Input, Button,
} from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getUserInitials, getFullName } from '../../helpers/functions';
import Messages from './messages';


const ChatForm = ({
    selectedUser, selectedConversation, onSubmitMessage,
}) => {
    const [form] = Form.useForm();

    const onFinish = ({ messageToSend }) => {
        onSubmitMessage(messageToSend);
        form.resetFields();
    };

    let cardTitle = null;
    if (selectedUser) {
        cardTitle = (
            <div className="flex" style={{ alignItems: 'center' }}>
                <Avatar style={{ backgroundColor: '#c4313f', marginRight: 10 }}>
                    {getUserInitials(selectedUser)}
                </Avatar>
                <b>{getFullName(selectedUser)}</b>
            </div>
        );
    }

    return (
        <Card
            className="telec-messages-card"
            title={cardTitle}
            actions={[
                <div className="flex-column" style={{ marginRight: 20 }}>
                    <Form onFinish={onFinish} form={form}>
                        <Form.Item
                            name="messageToSend"
                            style={{ marginBottom: 12 }}
                        >
                            <Input
                                autoFocus
                                style={{ marginBottom: 10 }}
                                placeholder="Tapez votre message ici"
                            />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                block
                                type="primary"
                                htmlType="submit"
                                disabled={!selectedUser}
                            >
                                Envoyer
                            </Button>
                        </Form.Item>
                    </Form>
                </div>,
            ]}
        >
            <div
                style={{ height: '55vh', overflow: 'auto' }}
                id="messages-container"
            >
                <Messages messages={selectedConversation ? selectedConversation.messages : []} />
            </div>
        </Card>
    );
};


ChatForm.propTypes = {
    selectedConversation: PropTypes.object,
    onSubmitMessage: PropTypes.func.isRequired,
    selectedUser: PropTypes.func,
};

ChatForm.defaultProps = {
    selectedUser: null,
    selectedConversation: null,
};


const stateToProps = state => ({
    selectedUser: state.chat.selectedUser,
    selectedConversation: state.chat.selectedConversation,
    conversations: state.chat.conversations,
    user: state.auth.user,
    // unseenMessages: state.notifications.unseenMessages,
});

export default connect(stateToProps)(ChatForm);
