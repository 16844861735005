import React, { useState } from 'react';
import {
    Card, Radio, Typography, Button, Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';

const { Title, Text } = Typography;

const OnboardingDecision = ({ submitDecision }) => {
    const [radioValue, setRadioValue] = useState(null);

    const confirmDecision = () => {
        Modal.confirm({
            title: 'Confirmation',
            icon: <ExclamationCircleOutlined />,
            onOk: () => submitDecision(radioValue),
            content: 'Êtes vous sûre de votre décision',
            okText: 'Confirmer',
            cancelText: 'Annuler',
        });
    };

    return (
        <div
            className="flex-column"
            style={{ alignItems: 'center', marginTop: 15 }}
        >
            <Title level={4}>
                Statut du patient
            </Title>

            <Radio.Group
                value={radioValue}
                onChange={e => setRadioValue(e.target.value)}
            >
                <Card>
                    <Radio value="no-covid">
                        Patient non COVID
                        <Text type="danger"> (Sorti du circuit Demo) </Text>
                    </Radio>
                </Card>
                <Card>
                    <Radio value="no-hospital">
                        Patient COVID+ nécessitant une hospitalisation
                        <Text type="danger"> (Sorti du circuit Demo) </Text>
                    </Radio>
                </Card>
                <Card>
                    <Radio value="yes-confirmed">
                        Patient confirmé COVID+
                        <Text type="success"> (Intégré dans le circuit Demo) </Text>
                    </Radio>
                </Card>
                <Card>
                    <Radio value="yes-suspect">
                        Patient suspect COVID+
                        <Text type="success"> (Intégré dans le circuit Demo) </Text>
                    </Radio>
                </Card>
            </Radio.Group>

            {radioValue ? (
                <Button
                    onClick={confirmDecision}
                    style={{ maxWidth: '40%', marginTop: 15 }}
                    type="primary"
                    block
                >
                    Valider
                </Button>
            ) : null}
        </div>
    );
};

OnboardingDecision.propTypes = {
    submitDecision: PropTypes.func.isRequired,
};

export default OnboardingDecision;
