/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import React from 'react';
import { formatDateFromIso, getFullName } from './functions';

// TODO refactor this !
export const parseValue = (val) => {
    if (typeof val === 'boolean') {
        if (val) return <span style={{ color: '#3FC431' }}> Oui </span>;
        return <span style={{ color: '#C4313F' }}> Non </span>;
    }

    return val;
};

export const keys = {
    gender: 'Sexe',
    phoneNumber: 'Numéro de téléphone',
    contact: 'Personne à contacter',
    firstName: 'Prénom',
    lastName: 'Nom',
    address: 'Adresse',
    email: 'Email',
    pregnant: 'Enceinte',
    duration: 'Durée (semaines)',
    employmentStatus: 'Employé',
    contaminationRisk: 'Risque de contamination Métier',
    employmentMedicalSupport:
    'Prise en charge métiers par la médecine de travail',
    symptomatology: 'Symptomatologie',
    date: 'Date de survenue des premiers symptômes',
    hospitalised: 'Hospitalisation suite à la survenue des symptômes',
    feverAntecedent: 'Antécédents de fièvre',
    caughType: 'Toux',
    throatSore: 'Maux de gorge',
    rhinorrhea: 'Écoulement nasal (rhinorrhée)',
    sibilance: 'Respiration sifflante (sibilance)',
    shortBreath: 'Souffle court',
    chestPain: 'Douleurs thoraciques',
    headache: 'Maux de tête',
    anosmia: 'Perte de l’odorat (anosmie)',
    ageusia: 'Perte du sens du goût (agueusie)',
    convulsions: 'Convulsions',
    fatigue: 'Fatigue / Malaise',
    myalgia: 'Douleurs musculaires (myalgie)',
    arthralgia: 'Douleurs articulaires (arthralgie)',
    abdominalPain: 'Douleurs abdominales',
    diarrhea: 'Diarrhée',
    vomiting: 'Vomissements / Nausées',
    rashes: 'Éruptions cutanées',
    other: 'Autre symptomes',
    contactWithAnotherCase: 'Contact avec un COVID+,',
    dateOfContactWithAnotherCase: 'Date de contact avec un COVID+',
    expectoration: 'Expectoration',
    pulmonaryDisease: 'BPCO',
    testPCR: 'Test PCR (Resultat 24h)',
    testSLG: 'Test Sérologique (Resultat 30min)',
    result: 'Resultat',
    HTA: 'HTA',
    diabetes: 'Diabéte',
    overWeight: 'Surpoids',
    birthDate: 'Date de naissance',
    score: 'score',
    _id: 'Code patient',
};

const patientContactChildren = (contact) => {
    const res = [];
    const elems = ['firstName', 'lastName', 'phoneNumber', 'email'];
    elems.map(elem => res.push({
        title: (
            <>
                <b>{`${keys[elem]}  : `}</b>
                {contact[elem]}
            </>
        ),
        key: `contact-${elem}`,
    }));
    return res;
};

const patientInfoChildren = (patient) => {
    const res = [];
    const elems = [
        '_id',
        'phoneNumber',
        'email',
        'gender',
        'birthDate',
        'address',
        'contact',
    ];
    elems.map((elem) => {
        switch (elem) {
            case 'birthDate':
                return res.push({
                    title: (
                        <>
                            <b>{`${keys.birthDate}  : `}</b>
                            {formatDateFromIso(patient.birthDate)}
                        </>
                    ),
                    key: elem,
                });
            case 'contact':
                return res.push({
                    title: () => (
                        <>
                            <b>{`${keys[elem]}  : `}</b>
                        </>
                    ),
                    key: elem,
                    children: patientContactChildren(patient.contact),
                });
            case 'gender':
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]}  : `}</b>
                            {patient[elem]}
                        </>
                    ),
                    key: elem,
                    children:
            patient.gender === 'Femelle'
                ? [
                    {
                        title: () => (
                            <>
                                <b>{`${keys.pregnant}  : `}</b>
                                {parseValue(patient.pregnant.value)}
                            </>
                        ),
                        key: `${elem}-value`,
                    },
                    {
                        title: () => (
                            <>
                                <b>{`${keys.duration}  : `}</b>
                                {patient.pregnant.duration}
                            </>
                        ),
                        key: `${elem}-duration`,
                    },
                ]
                : [],
                });
            default:
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]}  : `}</b>
                            {patient[elem]}
                        </>
                    ),
                    key: elem,
                });
        }
    });
    return res;
};

const patientSymptomatologyChildren = (symptomatology) => {
    const res = [];
    const elems = [
        'date',
        'hospitalised',
        'feverAntecedent',
        'caughType',
        'throatSore',
        'rhinorrhea',
        'sibilance',
        'shortBreath',
        'chestPain',
        'headache',
        'anosmia',
        'ageusia',
        'convulsions',
        'fatigue',
        'myalgia',
        'arthralgia',
        'abdominalPain',
        'diarrhea',
        'vomiting',
        'rashes',
        'expectoration',
        'other',
        'contactWithAnotherCase',
    ];
    elems.map((elem) => {
        switch (elem) {
            case 'feverAntecedent':
                if (symptomatology[elem].value) {
                    return res.push({
                        title: () => (
                            <>
                                <b>{`${keys[elem]}  : `}</b>
                                {parseValue(symptomatology[elem].value)}
                            </>
                        ),
                        key: elem,
                    });
                }
                break;

            case 'date':
                return res.push({
                    title: () => (
                        <>
                            <b>{`${keys[elem]}  : `}</b>
                            {formatDateFromIso(symptomatology[elem])}
                        </>
                    ),
                    key: elem,
                });
            case 'caughType':
                return res.push({
                    title: () => (
                        <>
                            <b>{`${keys.caughType}  : `}</b>
                            {parseValue(symptomatology.feverAntecedent.caughType)}
                        </>
                    ),
                    key: 'caughType',
                });
            case 'contactWithAnotherCase':
                if (symptomatology[elem]) {
                    return res.push({
                        title: () => (
                            <>
                                <b>{`${keys[elem]}  : `}</b>
                                {parseValue(symptomatology[elem])}
                            </>
                        ),
                        key: elem,

                        children: [
                            {
                                title: () => (
                                    <>
                                        <b>{`${keys.dateOfContactWithAnotherCase}  : `}</b>
                                        {formatDateFromIso(
                                            symptomatology.dateOfContactWithAnotherCase,
                                        )}
                                    </>
                                ),
                                key: 'dateOfContactWithAnotherCase',
                            },
                        ],
                    });
                }

                break;
            default:
                if (symptomatology[elem]) {
                    return res.push({
                        title: (
                            <>
                                <b>{`${keys[elem]}  : `}</b>
                                {parseValue(symptomatology[elem])}
                            </>
                        ),
                        key: elem,
                    });
                }
        }
    });
    return res;
};

const patientGeneralMedicalChildren = (patient) => {
    const elems = [
        'score',
        'testPCR',
        'testSLG',
        'pulmonaryDisease',
        'HTA',
        'diabetes',
        'overWeight',
    ];
    const res = [];
    elems.map((elem) => {
        if (elem === 'testPCR') {
            if (patient.testPCR.tested) {
                res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]}  : `}</b>
                            {parseValue(patient[elem].value)}
                        </>
                    ),
                    key: elem,
                    children: [
                        {
                            title: (
                                <>
                                    <b>{'Resultat  : '}</b>
                                    {patient[elem].value && patient[elem].result
                                        ? 'Positif'
                                        : 'Négatif'}
                                </>
                            ),
                            key: `${elem}-Result`,
                        },
                        {
                            title: (
                                <>
                                    <b>{'Date du test  : '}</b>
                                    {patient[elem].date}
                                </>
                            ),
                            key: `${elem}-Date`,
                        },
                    ],
                });
            }
        } else if (elem === 'testSLG') {
            if (patient.testSLG && patient.testSLG.tested) {
                res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]}  : `}</b>
                            {parseValue(patient[elem].value)}
                        </>
                    ),
                    key: elem,
                    children: [
                        {
                            title: (
                                <>
                                    <b>{'Resultat  : '}</b>
                                    {patient[elem].value && patient[elem].result
                                        ? 'Positif'
                                        : 'Négatif'}
                                </>
                            ),
                            key: `${elem}-Result`,
                        },
                        {
                            title: (
                                <>
                                    <b>{'Date du test  : '}</b>
                                    {patient[elem].date}
                                </>
                            ),
                            key: `${elem}-Date`,
                        },
                    ],
                });
            }
        } else if (patient[elem]) {
            res.push({
                title: (
                    <>
                        <b>{`${keys[elem]}  : `}</b>
                        {parseValue(patient[elem])}
                    </>
                ),
                key: elem,
            });
        }
    });
    return res;
};

const generalInfoTree = patient => [
    {
        title: <b>{getFullName(patient)}</b>,
        key: 'full-name',
        children: patientInfoChildren(patient),
    },
];

const symptomatologyTree = symptomatology => [
    {
        title: <b> Symptomatologie </b>,
        key: 'symptomatology',
        children: patientSymptomatologyChildren(symptomatology),
    },
];

const generalMedicalTree = patient => [
    {
        title: <b>Antécédents</b>,
        key: 'general-medical',
        children: patientGeneralMedicalChildren(patient),
    },
];

export { generalInfoTree, symptomatologyTree, generalMedicalTree };
