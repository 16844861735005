/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
    Steps, Card, Result, Button, Spin, Modal,
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import GeneralInfo from './generalInfo';
import MedicalRecord from '../../components/medicalRecord';
import {
    updateOnboardedStatus,
    setPendingListAsync,
    selectPatient,
    assignToNurseAsync,
} from '../../redux/patients/actions';
import OnboardingDecision from './onboardingDecision';
import { getFullName } from '../../helpers/functions';
import NursesList from '../../components/UsersListWithFilter';

const { Step } = Steps;

const SelectedPatient = ({
    nursesList,
    selectedPatient,
    updateOnboardedStatus,
    contentIsLoading,
    selectPatient,
    setPendingListAsync,
    assignToNurseAsync,
}) => {
    const [current, setCurrent] = useState(0);
    const [values, setValues] = useState({
        height: null,
        weight: null,
        smoker: null,
        numberOfCigarettes: null,
        smokerSince: null,
        medicalAntecedents: [],
        allergies: [],
        surgicalAntecedents: [],
        treatments: [],
    });

    const onChange = (name, value) => {
        setValues({ ...values, [name]: value });
    };

    const submitDecision = (decision) => {
        const body = {
            ...values,
            tobbaco: {
                value: values.smoker,
                numberOfCigarettes: values.numberOfCigarettes,
                smokerSince: values.smokerSince,
            },
            isOnboarded: decision,
            patientId: selectedPatient._id,
        };
        updateOnboardedStatus(body);
        setCurrent(3);
    };

    const goBackToPatientsList = () => {
        setPendingListAsync();
        selectPatient(null);
    };

    const confirmNurseAssignment = (nurse) => {
        Modal.confirm({
            title: 'Confirmation',
            icon: <ExclamationCircleOutlined />,
            onOk: () => assignToNurseAsync({
                nurseId: nurse._id,
                patientId: selectedPatient._id,
            }),
            content: `Êtes vous sûre de vouloir affecter l'infirmier(e) ${getFullName(
                nurse,
            )} au patient ${getFullName(selectedPatient)}`,
            okText: 'Confirmer',
            cancelText: 'Annuler',
        });
    };

    const showStep = () => {
        switch (current) {
            case 0:
                return (
                    <GeneralInfo />
                );
            case 1:
                return (
                    <MedicalRecord
                        handleFinish={() => setCurrent(2)}
                        onChange={onChange}
                        values={values}
                    />
                );
            case 2:
                return <OnboardingDecision submitDecision={submitDecision} />;
            case 3:
                return (
                    <Result
                        status="success"
                        title="Statut patient actualisé avec succès"
                        extra={
                            nursesList.length ? (
                                <Button type="primary" onClick={() => setCurrent(4)}>
                                    Affecter un(e) infirmiér(e)
                                </Button>
                            ) : (
                                <Button onClick={() => goBackToPatientsList()} type="primary">
                                    Retour a la liste des patients
                                </Button>
                            )
                        }
                    />
                );
            case 4:
                return (
                    <div className="nurses-list-cotainer">
                        <NursesList
                            data={nursesList}
                            title={`Affecter un(e) infirmier(e) au patient  ${getFullName(
                                selectedPatient,
                            )}`}
                            onSelect={confirmNurseAssignment}
                        />
                    </div>

                );

            default:
                return <div />;
        }
    };

    return (
        <div>
            <Spin spinning={contentIsLoading}>
                <Card>
                    {current < 3 ? (
                        <Steps current={current} onChange={setCurrent}>
                            <Step
                                title="Identification patient"
                            />
                            <Step title="DMI patient" />
                            <Step title="Décision" />
                        </Steps>
                    ) : null}

                    {showStep()}
                </Card>
            </Spin>
        </div>
    );
};

SelectedPatient.propTypes = {
    nursesList: PropTypes.array.isRequired,
    selectedPatient: PropTypes.object.isRequired,
    updateOnboardedStatus: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    selectPatient: PropTypes.func.isRequired,
    setPendingListAsync: PropTypes.func.isRequired,
    assignToNurseAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    nursesList: state.patients.nursesList,
    selectedPatient: state.patients.selectedPatient,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        updateOnboardedStatus,
        setPendingListAsync,
        selectPatient,
        assignToNurseAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(SelectedPatient);
