import React from 'react';
import { Layout } from 'antd';
import LoginForm from './form';
import './styles.css';

const { Footer } = Layout;

const Login = () => (
    <div className="flex login-container">
        <LoginForm />
        <div className="login-logo-division flex-column">
            <img
                src="/logo-demo-white.png"
                alt="logo demo"
                className="login-logo"
            />
            <Footer className="auth-footer">
                All rights reserved to Katomi ©2020
            </Footer>
        </div>
    </div>
);

export default Login;
