/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
    Form, Radio, Input, Button, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import FORM_VALIDATORS from '../../../helpers/formRules';
import { getFullName } from '../../../helpers/functions';

const { TextArea } = Input;

const { Text } = Typography;

const AddAdverseEffectForm = ({ patient, onAdd }) => {
    const [form] = Form.useForm();
    const [radioValue, setRadioValue] = useState();

    const onFinish = (values) => {
        const body = { ...values };
        body.patient = patient;
        onAdd(body);
    };

    const handleCausalityChange = (value) => {
        setRadioValue(value);
        form.setFieldsValue({ causality: value });
    };
    const showExtraInput = radioValue === 'Traitement administré'
    || radioValue === 'Procédure de prise en charge'
    || radioValue === 'Autre';

    return (
        <Form onFinish={onFinish} layout="vertical" form={form}>
            <Text strong style={{ marginBottom: 14 }}>
                {getFullName(patient)}
            </Text>

            <Form.Item
                rules={[FORM_VALIDATORS.required]}
                name="date"
                label="Date de survenue (jj/mm/aaaa)"
            >
                <Input />
            </Form.Item>
            <Form.Item
                rules={[FORM_VALIDATORS.required]}
                name="diagnosis"
                label="Diagnostic"
            >
                <TextArea rows={2} />
            </Form.Item>
            <Form.Item
                rules={[FORM_VALIDATORS.required]}
                name="signs"
                label="Signes et symptômes"
            >
                <TextArea rows={4} />
            </Form.Item>

            <Form.Item
                label="Intensité des symptômes"
                name="intensity"
                rules={[FORM_VALIDATORS.required]}
            >
                <Radio.Group>
                    <Radio value="Légère">Légère</Radio>
                    <Radio value="Modérée">Modérée</Radio>
                    <Radio value="Sévère">Sévère</Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item
                label="Lien de causalité du nouvel évènement"
                name="causality"
                rules={[FORM_VALIDATORS.required]}
            >
                <Radio.Group
                    value={radioValue}
                    onChange={e => handleCausalityChange(e.target.value)}
                >
                    <Radio value="COVID">COVID</Radio>
                    <Radio value="Maladie intercurrente">Maladie intercurrente</Radio>
                    <Radio value="Traitement administré">Traitement administré</Radio>
                    <Radio value="Procédure de prise en charge">
                        Procédure de prise en charge
                    </Radio>
                    <Radio value="Autre">Autre</Radio>
                    <Radio value="Pas de causalité">Pas de causalité</Radio>
                </Radio.Group>
            </Form.Item>
            {showExtraInput ? (
                <Form.Item
                    label="Préciser"
                    name="extraCausality"
                    rules={[FORM_VALIDATORS.required]}
                >
                    <Input />
                </Form.Item>
            ) : null}
            <Form.Item
                label="Conduite à tenir"
                name="instructions"
                rules={[FORM_VALIDATORS.required]}
            >
                <TextArea rows={3} />
            </Form.Item>

            <Form.Item>
                <Button
                    block
                    className="center-button"
                    style={{ maxWidth: '40%' }}
                    shape="round"
                    type="primary"
                    htmlType="submit"
                >
                    Valider
                </Button>
            </Form.Item>
        </Form>
    );
};

AddAdverseEffectForm.propTypes = {
    patient: PropTypes.object.isRequired,
    onAdd: PropTypes.func.isRequired,
};


export default AddAdverseEffectForm;
