import axios from 'axios';
import { API_PATIENTS, API_REQ_HEADER, API_ASSIGNMENTS } from './config';

const getOnboarded = async () => {
    const res = await axios.get(`${API_PATIENTS}/onboarded`, API_REQ_HEADER);
    return res.data;
};


const getAllPatients = async () => {
    const res = await axios.get(API_PATIENTS, API_REQ_HEADER);
    return res.data;
};


const setOnboardedStatus = async (body) => {
    const res = await axios.post(`${API_PATIENTS}/onboarded`, body, API_REQ_HEADER);
    return res.data;
};

const getPending = async () => {
    const res = await axios.get(`${API_PATIENTS}/pending`, API_REQ_HEADER);
    return res.data;
};

const create = async (data) => {
    const res = await axios.post(`${API_PATIENTS}/`, data, API_REQ_HEADER);
    return res.data;
};

const assignToNurse = async (data) => {
    const res = await axios.post(API_ASSIGNMENTS, data, API_REQ_HEADER);
    return res.data;
};

const getById = async (id) => {
    const res = await axios.get(`${API_PATIENTS}/${id}`, API_REQ_HEADER);
    return res.data;
};

const update = async (id, data) => {
    const res = await axios.put(`${API_PATIENTS}/${id}`, data, API_REQ_HEADER);
    return res.data;
};

export {
    getOnboarded, getPending, create, getById, update, setOnboardedStatus,
    assignToNurse, getAllPatients,
};
