import axios from 'axios';
import { API_CONSULTATIONS, API_REQ_HEADER } from './config';

const create = async (data) => {
    const res = await axios.post(`${API_CONSULTATIONS}/`, data, API_REQ_HEADER);
    return res.data;
};


const getById = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/${id}`, API_REQ_HEADER);
    return res.data;
};


const getByPatient = async (id) => {
    const res = await axios.get(`${API_CONSULTATIONS}/pt/${id}`, API_REQ_HEADER);
    return res.data;
};


// const update = async (id, data) => {
//     const res = await axios.get(`${API_CONSULTATIONS}/${id}`, data, API_REQ_HEADER);
//     return res.data;
// };

export {
    create, getById, getByPatient,
};
