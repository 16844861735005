import React, { useState, useEffect } from 'react';
import { Steps, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setOnboardedListAsync,
    setPatientsList,
    selectPatient,
} from '../../../redux/patients/actions';
import PatientsList from '../../../components/UsersListWithFilter';
import AddForm from './form';

const { Step } = Steps;

const AddAdverseEvent = ({
    setOnboardedListAsync,
    setPatientsList,
    patientsList,
    contentIsLoading,
    onAdd,
    selectPatient,
    selectedPatient,
}) => {
    const [current, setCurrent] = useState(0);

    const onSelectPatient = (patient) => {
        selectPatient(patient);
    };

    useEffect(() => {
        if (selectedPatient) {
            setCurrent(1);
        }
        //  else {
        //     if (current) setCurrent(0)
        // }
    }, [selectedPatient]);

    useEffect(() => {
        setOnboardedListAsync();
        return () => {
            setPatientsList([]);
            selectPatient(null);
        };
    }, []);

    return (
        <Spin spinning={contentIsLoading}>
            <Steps style={{ marginBottom: 14 }} current={current}>
                <Step title="Sélectionner un patient" />
                <Step title="Signaler l'événement" />
            </Steps>
            {current === 0 ? (
                <PatientsList
                    data={patientsList}
                    title="Liste des patients"
                    onSelect={onSelectPatient}
                />
            ) : (
                <AddForm patient={selectedPatient} onAdd={onAdd} />
            )}
        </Spin>
    );
};

AddAdverseEvent.propTypes = {
    setOnboardedListAsync: PropTypes.func.isRequired,
    setPatientsList: PropTypes.func.isRequired,
    patientsList: PropTypes.array.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    onAdd: PropTypes.func.isRequired,
    selectPatient: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
};

AddAdverseEvent.defaultProps = {
    selectedPatient: null,
};

const stateToProps = state => ({
    patientsList: state.patients.patientsList,
    contentIsLoading: state.loading.contentIsLoading,
    selectedPatient: state.patients.selectedPatient,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setOnboardedListAsync,
        setPatientsList,
        selectPatient,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AddAdverseEvent);
