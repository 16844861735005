/* eslint-disable array-callback-return */
/* eslint-disable no-underscore-dangle */
import {
    SET_PATIENTS_LIST, SET_PATIENT, SET_NURSES, UPDATE_PATIENT,
} from '../actions';

const initialState = {
    selectedPatient: null,
    patientsList: [],
    nursesList: [],
};

const patients = (state = initialState, action) => {
    switch (action.type) {
        case SET_PATIENTS_LIST:
            return { ...state, patientsList: action.payload };
        case SET_PATIENT:
            return { ...state, selectedPatient: action.payload };
        case UPDATE_PATIENT:
            // eslint-disable-next-line no-case-declarations
            const patientsList = [];
            state.patientsList.map((patient) => {
                if (patient._id === action.payload._id) {
                    patientsList.push(action.payload);
                } else patientsList.push(patient);
            });
            return { ...state, patientsList };

        case SET_NURSES:
            return { ...state, nursesList: action.payload };
        default:
            return state;
    }
};

export default patients;
