/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
    Form, Input, Typography, Button, Table, message,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import FORM_VALIDATORS from '../../../helpers/formRules';

const { Title } = Typography;

const AddPrescription = ({ handleNext }) => {
    const [treatments, setTreatments] = useState([]);
    const [form] = Form.useForm();

    const onFinish = (values) => {
        const newTreatments = [...treatments];
        newTreatments.push({ ...values, key: Math.random() });
        setTreatments(newTreatments);
        message.success('Traitement ajouté avec succès');
        form.resetFields();
    };

    const onDelete = (row) => {
        const newArr = treatments.filter(item => item.key !== row.key);
        setTreatments(newArr);
        message.success('Traitement supprimé avec succès');
    };
    const columns = [

        {
            title: <b> Traitement </b>,
            dataIndex: 'treatment',
            key: 'treatment',
        },
        {
            title: <b> Posologie </b>,
            dataIndex: 'posology',
            key: 'posology',
        },
        {
            title: <b> Durée </b>,
            dataIndex: 'duration',
            key: 'duration',
        },
        {
            title: <b> Actions </b>,
            key: 'action',
            render: a => (
                <Button
                    danger
                    shape="round"
                    onClick={() => {
                        onDelete(a);
                    }}
                >
                    Supprimer
                </Button>
            ),
        },
    ];

    return (
        <div className="prescription-form-container">
            <Form onFinish={onFinish} form={form}>
                <div id="prescription-form">
                    <Title level={4}> Prescription :</Title>
                    <div style={{ marginTop: 15 }}>
                        <Form.Item
                            style={{ maxWidth: '100%' }}
                            name="treatment"
                            rules={[FORM_VALIDATORS.required]}
                            label="Traitement"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="posology"
                            rules={[FORM_VALIDATORS.required]}
                            label="Posologie"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                            name="duration"
                            rules={[FORM_VALIDATORS.required]}
                            label="Durée"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item>
                            <Button
                                type="primary"
                                ghost
                                htmlType="submit"
                                block
                            >
                                <PlusOutlined />
                                Ajouter
                            </Button>
                        </Form.Item>
                    </div>
                </div>
            </Form>
            <Table dataSource={treatments} columns={columns} locale={{ emptyText: 'Aucun traitement' }} />


            <Button
                htmlType="submit"
                type="primary"
                shape="round"
                onClick={() => handleNext('treatments', treatments)}
                className="center-button"
            >
                Suivant
            </Button>
        </div>
    );
};

AddPrescription.propTypes = {
    handleNext: PropTypes.func.isRequired,
};

export default AddPrescription;
