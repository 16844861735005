/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Button, Spin, Table, Typography, Tooltip,
} from 'antd';
import { PlusOutlined, InfoCircleTwoTone } from '@ant-design/icons';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setOnboardedListAsync,
    selectPatientAsync,
    setPatientsList,
    updatePatientAsync,
    selectPatient,
} from '../../redux/patients/actions';
import {
    setConsultationListAsync,
    setConsultationList,
} from '../../redux/consultations/actions';
import AddConsultation from './add';
import { getFullName } from '../../helpers/functions';
import MedicalRecord from '../../components/medicalRecord';
import PatientDocuments from '../../components/PatientDocuments';
import ConsultationsHistory from './consultationsHistory';
import PatientStats from './patientsStats';
import PatientGeneralInfo from '../Patients/generalInfo';
import HeadBreadCrumb from './headBreadCrumbs';
import './style.css';
import CovidTests from './covidTests';

const { Title } = Typography;

const colorPatientStatus = (status) => {
    let color;
    switch (status) {
        case 'En cours de suivi':
            color = 'rgba(0,0,0,.85)';
            break;
        case 'Guéri':
            color = '#52c41a';
            break;
        case 'Hospitalisé':
            color = '#faad14';
            break;
        case 'Décédé':
            color = '#ff4d4f';
            break;
        default:
            color = '';
    }
    return color;
};

const Consultations = ({
    setOnboardedListAsync,
    setPatientsList,
    selectPatientAsync,
    patientsList,
    selectedPatient,
    updatePatientAsync,
    selectPatient,
    contentIsLoading,
    setConsultationListAsync,
    setConsultationList,
}) => {
    const [showAdd, setShowAdd] = useState(false);
    const [showDMI, setShowDMI] = useState(false);
    const [showConsultationHistory, setShowConsultationHistory] = useState(false);
    const [showPatientDocuments, setShowPatientDocuments] = useState(false);
    const [showStats, setShowStats] = useState(false);
    const [DMIValues, setDMIValues] = useState(null);
    const [showPatientInfo, setShowPatientInfo] = useState();
    const [showCovidTests, setShowCovidTests] = useState();

    const columns = [
        {
            title: <b>Nom et Prénom </b>,
            key: 'name',
            render: (_, record) => (
                <div
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                        selectPatientAsync(record._id);
                        setShowPatientInfo(true);
                    }}
                >
                    <Tooltip title="Cliquer pour voir les coordonnées du patient">
                        <InfoCircleTwoTone className="left-icon" />
                        {getFullName(record)}
                    </Tooltip>
                </div>
            ),
        },
        {
            title: <b> Statut </b>,
            key: 'status',
            sorter: (a, b) => a.status.length - b.status.length,
            render: (_, record) => (
                <span style={{ color: colorPatientStatus(record.status) }}>
                    {record.status}
                </span>
            ),
        },
        {
            title: <b> Actions </b>,
            key: 'action',
            render: (_, record) => (
                <div className="flex" style={{ justifyContent: 'space-around' }}>
                    <Button
                        type="link"
                        onClick={() => {
                            selectPatientAsync(record._id);
                            setConsultationListAsync(record._id);
                            setShowConsultationHistory(true);
                        }}
                    >
                        Historique
                    </Button>
                    <Button
                        type="link"
                        onClick={() => {
                            selectPatientAsync(record._id);
                            setConsultationListAsync(record._id);
                            setShowStats(true);
                        }}
                    >
                        Stats
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            selectPatientAsync(record._id);
                            setShowDMI(true);
                        }}
                    >
                        DMI
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            selectPatientAsync(record._id);
                            setShowCovidTests(true);
                        }}
                    >
                        Tests Covid
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            selectPatientAsync(record._id);
                            setShowPatientDocuments(true);
                        }}
                    >
                        Photos et imagerie
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            selectPatientAsync(record._id);
                            setShowAdd(true);
                        }}
                    >
                        <PlusOutlined className="left-icon" />
                        Consultation
                    </Button>
                </div>
            ),
        },
    ];

    useEffect(() => {
        setOnboardedListAsync();
        return () => {
            setPatientsList([]);
            selectPatient(null);
        };
    }, []);

    const unselectPatient = () => {
        setShowDMI(false);
        selectPatient(false);
        setConsultationList([]);
        setDMIValues(null);
        setShowAdd(null);
        setShowPatientDocuments(null);
        setShowConsultationHistory(null);
        setShowStats(null);
        setShowPatientInfo(null);
        setShowCovidTests(null);
    };

    useEffect(() => {
        if (selectedPatient && showDMI) {
            setDMIValues({
                ...selectedPatient,
                smoker: selectedPatient.tobbaco.value,
                numberOfCigarettes: selectedPatient.tobbaco.numberOfCigarettes,
                smokerSince: selectedPatient.tobbaco.smokerSince,
            });
        }
        if (!selectedPatient) {
            unselectPatient();
        }
    }, [selectedPatient]);

    const handleUpdateDMIDatatables = (key, val) => {
        updatePatientAsync(DMIValues._id, { [key]: val });
    };

    if (selectedPatient) {
        if (showPatientInfo) {
            return (
                <div>
                    <HeadBreadCrumb
                        handleGoBack={unselectPatient}
                        sectionName="Coordonnées"
                    />
                    <PatientGeneralInfo />
                </div>
            );
        }

        if (showAdd) {
            return (
                <div>
                    <HeadBreadCrumb
                        handleGoBack={unselectPatient}
                        sectionName="Ajout d'une consultation"
                    />
                    <AddConsultation handleCloseAdd={() => setShowAdd(false)} />
                </div>
            );
        }
        if (showConsultationHistory) {
            return (
                <div>
                    <HeadBreadCrumb
                        handleGoBack={unselectPatient}
                        sectionName="Historique de consultations"
                    />
                    <ConsultationsHistory />
                </div>
            );
        }

        if (showStats) {
            return (
                <div>
                    <HeadBreadCrumb
                        handleGoBack={unselectPatient}
                        sectionName="Statistiques par consultation"
                    />
                    <PatientStats />
                </div>
            );
        }

        if (showPatientDocuments) {
            return (
                <div>
                    <HeadBreadCrumb
                        handleGoBack={unselectPatient}
                        sectionName="Photos et imagerie"
                    />
                    <PatientDocuments />
                </div>
            );
        }

        if (showDMI && DMIValues) {
            return (
                <div>
                    <HeadBreadCrumb handleGoBack={unselectPatient} sectionName="DMI" />
                    <MedicalRecord
                        onChange={handleUpdateDMIDatatables}
                        values={DMIValues}
                        readOnlyInputs
                    />
                </div>
            );
        }

        if (showCovidTests) {
            return (
                <div>
                    <HeadBreadCrumb
                        handleGoBack={unselectPatient}
                        sectionName="Tests Covid"
                    />
                    <CovidTests />
                </div>
            );
        }
    }

    return (
        <div style={{ margin: '20px 50px' }}>
            <Spin spinning={contentIsLoading}>
                <Title level={2} style={{ color: '#c4313f' }}>
                    Patients suivis
                </Title>
                <Table
                    columns={columns}
                    dataSource={patientsList}
                    locale={{
                        emptyText: 'Aucun patient',
                        sort: 'nothing',
                        cancelSort: 'Cliquer pour annuler le trie',
                        triggerAsc: 'Trier',
                        triggerDesc: 'Trier',
                    }}
                />
            </Spin>
        </div>
    );
};

Consultations.propTypes = {
    setOnboardedListAsync: PropTypes.func.isRequired,
    setPatientsList: PropTypes.func.isRequired,
    selectPatientAsync: PropTypes.func.isRequired,
    patientsList: PropTypes.array.isRequired,
    selectedPatient: PropTypes.object,
    updatePatientAsync: PropTypes.func.isRequired,
    selectPatient: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    setConsultationListAsync: PropTypes.func.isRequired,
    setConsultationList: PropTypes.func.isRequired,
};

Consultations.defaultProps = {
    selectedPatient: null,
};

const stateToProps = state => ({
    patientsList: state.patients.patientsList,
    selectedPatient: state.patients.selectedPatient,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setOnboardedListAsync,
        setPatientsList,
        selectPatientAsync,
        updatePatientAsync,
        selectPatient,
        setConsultationListAsync,
        setConsultationList,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Consultations);
