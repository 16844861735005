/* eslint-disable no-underscore-dangle */
/* eslint-disable consistent-return */
import React, { useState } from 'react';
import {
    Table, Typography, Button, Modal, Divider, Statistic, message,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFullName, formatDateTimeFromIso } from '../../helpers/functions';
import { getPatientConsultations } from '../../api/stats';

const { Text, Title } = Typography;

const ConsultationModal = ({ open, onClose, data }) => (
    <Modal visible={open} onCancel={onClose} title="Nombre de consultations" footer={null}>
        <div className="flex" style={{ justifyContent: 'space-around' }}>
            <Statistic title="Médecin" value={data.doctor || 0} />
            <Divider type="vertical" />
            <Statistic title="Infirmier" value={data.nurse || 0} />
        </div>
    </Modal>
);

ConsultationModal.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    data: PropTypes.object,
};

ConsultationModal.defaultProps = {
    data: {},
};

const parseEligibility = (patient) => {
    if (!patient.isEligible) return <Text type="danger"> Non eligible au circuit</Text>;
    if (!patient.isOnboarded) return <Text strong> Patient en attente</Text>;
    if (patient.isOnboarded === 'yes-confirmed') return <Text type="success"> Patient confirmé COVID+</Text>;
    if (patient.isOnboarded === 'yes-suspect') return <Text type="success"> Patient suspect COVID+</Text>;
    if (patient.isOnboarded === 'no-hospital') return <Text type="danger"> Non eligible (Hospitalisé)</Text>;
    if (patient.isOnboarded === 'no-covid') return <Text type="danger"> Non eligible (Non Covid)</Text>;
};

const columns = cb => [
    {
        title: <b>Nom et Prénom </b>,
        key: 'name',
        render: (_, record) => <p>{getFullName(record)}</p>,
    },
    {
        title: <b>État </b>,
        key: 'eligibility',
        render: (_, record) => parseEligibility(record),
    },
    {
        title: <b>Statut </b>,
        key: 'status',
        render: (_, record) => (record.status),
    },
    {
        title: <b> Médecin </b>,
        key: 'doctor',
        render: (_, record) => (record.doctor ? getFullName(record.doctor) : ''),
    },
    {
        title: <b> Infirmier </b>,
        key: 'nurse',
        render: (_, record) => (record.nurse ? getFullName(record.nurse) : ''),
    },
    {
        title: <b> Créé par </b>,
        key: 'creator',
        render: (_, record) => (record.creator ? getFullName(record.creator) : ''),
    },
    {
        title: <b> Date de création </b>,
        key: 'date',
        render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
    },
    {
        title: <b> Actions </b>,
        key: '_id',
        render: (_, record) => <Button type="primary" ghost onClick={() => cb(record._id)}> Consultations</Button>,
    },
];

const PatientsList = ({ patientsList }) => {
    const [data, setData] = useState();

    const getData = async (patientId) => {
        try {
            const res = await getPatientConsultations(patientId);
            if (res.data) { setData(res.data); } else {
                setData({ doctor: 0, nurse: 0 });
            }
        } catch (err) {
            message.error("Une erreur s'est produite");
        }
    };

    return (
        <div style={{ marginTop: 24 }}>
            <div className="flex" style={{ alignItems: 'baseline' }}>
                <Title style={{ color: 'rgb(196, 49, 63)' }} level={3}>
                    Liste des patients
                </Title>
                <Text strong className="m-left-small">
                    (Nombre totale&nbsp;
                    {patientsList.length || '0'}
                    )
                </Text>
            </div>


            <Table columns={columns(getData)} dataSource={patientsList} />
            {data ? (
                <ConsultationModal open={data} onClose={() => setData(null)} data={data} />
            ) : null}
        </div>
    );
};

PatientsList.propTypes = {
    patientsList: PropTypes.array.isRequired,
};

const stateToProps = state => ({
    patientsList: state.stats.patientsList,
});

export default connect(stateToProps)(PatientsList);
