/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Button, Table, Typography, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setEventsList,
    setFullListAsync,
    createEventAsync,
    selectEvent,
} from '../../redux/adverseEvents/actions';
import { getFullName, formatDateTimeFromIso } from '../../helpers/functions';
import AddAdverseEvent from './add';
import ConsultModal from './consult';
import UpdateAdverseEvent from './update';

const { Title } = Typography;

const AdverseEvents = ({
    setEventsList,
    setFullListAsync,
    contentIsLoading,
    eventsList,
    createEventAsync,
    selectEvent,
    selectedEvent,
}) => {
    const [openAdd, setOpenAdd] = useState(false);
    const [openShow, setOpenShow] = useState(false);
    const [openUpdate, setOpenUpdate] = useState(false);

    const columns = [
        {
            title: <b> Date de l&apos;événement </b>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },
        {
            title: <b> patient </b>,
            key: 'patient',
            render: (_, record) => <p>{getFullName(record.patient)}</p>,
        },
        {
            title: <b> Statut de l&apos;événement </b>,
            key: 'status',
            dataIndex: 'status',
        },
        {
            title: <b> Actions </b>,
            key: 'action',
            render: (_, record) => (
                <>
                    <Button
                        type="link"
                        onClick={() => {
                            selectEvent(record);
                            setOpenShow(true);
                        }}
                    >
                        Consulter
                    </Button>
                    <Button
                        type="primary"
                        ghost
                        onClick={() => {
                            selectEvent(record);
                            setOpenUpdate(true);
                        }}
                    >
                        Changer le statut
                    </Button>
                </>
            ),
        },
    ];

    const onAdd = (body) => {
        createEventAsync(body);
        setOpenAdd(false);
    };

    useEffect(() => {
        setFullListAsync();
        return () => {
            setEventsList([]);
        };
    }, []);

    const closeConsult = () => {
        selectEvent(null);
        setOpenShow(false);
    };

    const closeUpdate = () => {
        selectEvent(null);
        setOpenUpdate(false);
    };

    if (openUpdate && selectedEvent) {
        return <UpdateAdverseEvent open={openUpdate} onClose={closeUpdate} />;
    }

    if (openShow && selectedEvent) {
        return <ConsultModal open={openShow} onClose={closeConsult} />;
    }

    return (
        <div style={{ margin: '20px 50px' }}>
            {openAdd ? (
                <AddAdverseEvent onAdd={onAdd} />
            ) : (
                <Spin spinning={contentIsLoading}>
                    <Title level={2} style={{ color: '#c4313f' }}>
                        Evénements Indésirables
                    </Title>

                    <Button
                        type="primary"
                        shape="round"
                        style={{ marginBottom: 24 }}
                        onClick={() => setOpenAdd(true)}
                    >
                        Ajouter un événement indésirable
                    </Button>

                    <Table
                        dataSource={eventsList}
                        columns={columns}
                        locale={{ emptyText: 'Aucun événement' }}
                    />
                </Spin>
            )}
        </div>
    );
};

AdverseEvents.propTypes = {
    setEventsList: PropTypes.func.isRequired,
    setFullListAsync: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    eventsList: PropTypes.array.isRequired,
    createEventAsync: PropTypes.func.isRequired,
    selectEvent: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object,
};

AdverseEvents.defaultProps = {
    selectedEvent: null,
};

const stateToProps = state => ({
    eventsList: state.adverseEvents.eventsList,
    selectedPatient: state.patients.selectedPatient,
    contentIsLoading: state.loading.contentIsLoading,
    selectedEvent: state.adverseEvents.selectedEvent,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setEventsList,
        setFullListAsync,
        createEventAsync,
        selectEvent,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AdverseEvents);
