/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Avatar, List } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { getUserInitials, getFullName } from '../../helpers/functions';

const getClassNames = isSelected => classnames({
    'selected-contact': isSelected,
});


const isUnseen = seen => classnames({
    'unseen-message': !seen,
});

// eslint-disable-next-line max-len
const ConversationsList = ({ conversations, ...props }) => {
    const {
        user, selectedUser, onClick,
    } = props;

    const getSender = (msg, remoteUserFirstName) => (msg.sender === user._id ? 'Vous' : `${remoteUserFirstName}`);

    const isMessageUnseen = message => message.seen || message.sender === user._id;

    // const isUnseen = !lastMessage.seen && !isSelected && lastMessage.sender !== user._id;
    return (
        <List
            itemLayout="horizontal"
            dataSource={Object.values(conversations)}
            className="contacts-list"
            renderItem={(conv, index) => (
                <List.Item
                    className={getClassNames(
                        selectedUser ? conv.user === selectedUser._id : false,
                    )}
                    key={`conv-${index}`}
                    onClick={() => onClick(conv)}
                >
                    <List.Item.Meta
                        avatar={(
                            <Avatar style={{ backgroundColor: '#c4313f' }}>
                                {getUserInitials(conv.user_info)}
                            </Avatar>
                        )}
                        title={getFullName(conv.user_info)}
                        description={(
                            <div className="flex" style={{ alignItems: 'baseline' }}>
                                <div>
                                    <b>
                                        {`${getSender(
                                            conv.messages[0].sender,
                                            conv.user_info.lastName,
                                        )} `}
                                    </b>
                                    {`${conv.messages[0].content.substr(0, 10)}..`}
                                </div>
                                <span className={isUnseen(isMessageUnseen(conv.messages[0]))} />
                            </div>
                        )}
                    />
                </List.Item>
            )}
        />
    );
};

ConversationsList.propTypes = {
    onClick: PropTypes.func,
    conversations: PropTypes.object.isRequired,
    selectedUser: PropTypes.object,
    showSelectedUser: PropTypes.bool,
    selectedConversation: PropTypes.object,
    user: PropTypes.object.isRequired,
};

ConversationsList.defaultProps = {
    onClick: () => null,
    selectedUser: null,
    showSelectedUser: true,
    selectedConversation: {},
};

const stateToProps = state => ({
    selectedUser: state.chat.selectedUser,
    doctors: state.chat.doctors,
    patients: state.chat.patients,
    conversations: state.chat.conversations,
    user: state.auth.user,
});

export default connect(stateToProps, null)(ConversationsList);
