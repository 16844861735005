/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import { parseValue } from './patients';
import { formatDateTimeFromIso, getFullName } from './functions';

const keys = {
    date: 'Date de la consultation',
    treatments: 'Prescription',
    generalObservations: 'Observations générales',
    barrierMeasures: 'Mesures barrières',
    mask: 'Port du masque',
    hygiene: 'Hygiène des mains et l’utilisation de gel hydroalcoolique',
    education: 'Education thérapeutique / Prévention',
    heartRate: 'Fréquence cardiaque',
    respiratoryRate: 'Fréquence respiratoire',
    oxygenSaturation: 'Saturation en oxygène',
    oxygenDebit: 'Debit',
    bodyTemperature: 'Température',
    testPCR: 'Test PCR (Resultat 24h)',
    testSLG: 'Test Sérologique (Resultat 30min)',
    result: 'Resultat',
    bloodPressure: 'Pression Artérielle',
    consultationType: 'Type de consultation',
    systolique: 'TA Systolique',
    diastolique: 'TA Diastolique',
    familyNumber: 'Nombre des personnes vivant sous le même toit',
    clinicalExamination: 'Examen clinique',
    observation: 'Observation',
    // TODO add creator (doctor|nurse).
};

const mapGeneralObservations = (generalObservation) => {
    const elems = ['barrierMeasures', 'mask', 'hygiene', 'education'];
    const res = [];
    elems.map((elem) => {
        if (generalObservation[elem]) {
            return res.push({
                title: (
                    <>
                        <b>{`${keys[elem]} : `}</b>
                    </>
                ),
                key: elem,
                children: generalObservation[elem]['Observées']
                    ? [
                        {
                            title: (
                                <>
                                    <b>{'Observées : '}</b>
                                    {generalObservation[elem]['Observées']}
                                </>
                            ),
                            key: `observé-${elem}`,
                        },
                        {
                            title: (
                                <>
                                    <b>{'Rappelées : '}</b>
                                    {generalObservation[elem]['Rappelées']}
                                </>
                            ),
                            key: `Rappelées-${elem}`,
                        },
                    ]
                    : [
                        {
                            title: (
                                <>
                                    <b>{'Respecté : '}</b>
                                    {generalObservation[elem]['Respecté']}
                                </>
                            ),
                            key: `observé-${elem}`,
                        },
                        {
                            title: (
                                <>
                                    <b>{'Rappelées : '}</b>
                                    {generalObservation[elem]['Rappelées']}
                                </>
                            ),
                            key: `Rappelées-${elem}`,
                        },
                    ],
            });
        }
    });
    if (generalObservation.familyNumber) {
        res.push({
            title: (
                <>
                    <b>{`${keys.familyNumber} : `}</b>
                    {generalObservation.familyNumber}
                </>
            ),
            key: 'familyNumber',
        });
    }

    return res;
};

const prepareClinicalExamination = (consultation) => {
    const res = [];
    const elems = [
        'oxygenSaturation',
        'bodyTemperature',
        'heartRate',
        'respiratoryRate',
        'bloodPressure',
        'observation',
    ];
    elems.map((elem) => {
        switch (elem) {
            case 'bloodPressure':
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]} : `}</b>
                            {`${consultation[elem].systolique} / ${consultation[elem].diastolique}`}
                        </>
                    ),
                    key: elem,
                });
            case 'oxygenSaturation':
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]} : `}</b>
                            {`${consultation[elem].value} % (${consultation[elem].oxygenType})`}
                        </>
                    ),
                    key: elem,
                    children:
        consultation[elem].oxygenType === 'Oxygénothérapie'
            ? [
                {
                    title: (
                        <>
                            <b>Débit d&apos;oxygéne :&nbsp;</b>
                            {`${consultation[elem].oxygenDebit}`}
                        </>
                    ),
                    key: `debit-${elem}`,
                },
            ]
            : [],
                });
            default:
                if (consultation[elem]) {
                    return res.push({
                        title: (
                            <>
                                <b>{`${keys[elem]} : `}</b>
                                {`${consultation[elem]}`}
                            </>
                        ),
                        key: elem,
                    });
                }
        }
    });
    return res;
};

const mapPrescription = (prescription) => {
    const res = [];
    prescription.map((item, i) => res.push({
        title: (
            <>
                <b>{`${i + 1} : `}</b>
            </>
        ),
        key: `tr-${i}`,
        children: [
            {
                title: (
                    <>
                        <b>{'Traitement : '}</b>
                        {item.treatment}
                    </>
                ),
                key: `treatment-${i}`,
            },
            {
                title: (
                    <>
                        <b>{'Posologie : '}</b>
                        {item.posology}
                    </>
                ),
                key: `posology-${i}`,
            },
            {
                title: (
                    <>
                        <b>{'Durée : '}</b>
                        {item.duration}
                    </>
                ),
                key: `duration-${i}`,
            },
        ],
    }));
    return res;
};


const prepareUserName = (consultation) => {
    if (consultation.nurse) {
        return getFullName(consultation.nurse);
    }
    return (getFullName(consultation.doctor));
};
const prepareTree = (consultation) => {
    const res = [];

    const elems = [
        'consultationType',
        'testPCR',
        'testSLG',
        'patientStatus',
        'clinicalExamination',
        'treatments',
        'generalObservations',
    ];
    elems.map((elem) => {
        switch (elem) {
            case 'consultationType':
                if (consultation[elem].value === 'Physique') {
                    return res.push({
                        title: (
                            <>
                                <b>{`${keys[elem]} : `}</b>
                                {consultation[elem].value}
                            </>
                        ),
                        key: elem,
                        children: [
                            {
                                title: (
                                    <>
                                        <b>{'Payé : '}</b>
                                        {consultation[elem].payed}
                                    </>
                                ),
                                key: `payed-${elem}`,
                            },
                            {
                                title: (
                                    <>
                                        <b>{'Montant : '}</b>
                                        {consultation[elem].amount}
                                    </>
                                ),
                                key: `amount-${elem}`,
                            },
                        ],
                    });
                }
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]} : `}</b>
                            {consultation[elem].value}
                        </>
                    ),
                    key: elem,
                });
            case 'testPCR':
                if (consultation.testPCR && consultation.testPCR.value) {
                    return res.push({
                        title: (
                            <>
                                <b>{`${keys[elem]} : `}</b>
                                {parseValue(consultation[elem].value)}
                            </>
                        ),
                        key: elem,
                        children: [
                            {
                                title: (
                                    <>
                                        <b>{'Resultat : '}</b>
                                        {consultation[elem].value && consultation[elem].result
                                            ? 'Positif'
                                            : 'Négatif'}
                                    </>
                                ),
                                key: `${elem}-Result`,
                            },
                            {
                                title: (
                                    <>
                                        <b>{'Date du test : '}</b>
                                        {consultation[elem].date}
                                    </>
                                ),
                                key: `${elem}-Date`,
                            },
                        ],
                    });
                }
                break;
            case 'testSLG':
                if (consultation.testSLG && consultation.testSLG.value) {
                    return res.push({
                        title: (
                            <>
                                <b>{`${keys[elem]} : `}</b>
                                {parseValue(consultation[elem].value)}
                            </>
                        ),
                        key: elem,
                        children: [
                            {
                                title: (
                                    <>
                                        <b>{'Resultat : '}</b>
                                        {consultation[elem].value && consultation[elem].result
                                            ? 'Positif'
                                            : 'Négatif'}
                                    </>
                                ),
                                key: `${elem}-Result`,
                            },
                            {
                                title: (
                                    <>
                                        <b>{'Date du test : '}</b>
                                        {consultation[elem].date}
                                    </>
                                ),
                                key: `${elem}-Date`,
                            },
                        ],
                    });
                }
                break;
            case 'clinicalExamination':
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]} : `}</b>
                        </>
                    ),
                    key: elem,
                    children: prepareClinicalExamination(consultation),
                });
            case 'generalObservations':
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]} : `}</b>
                        </>
                    ),
                    key: elem,
                    children: mapGeneralObservations(consultation[elem] || []),
                });
            case 'patientStatus':
                if (consultation[elem].value === 'Aggravation') {
                    return res.push({
                        title: (
                            <>
                                <b>{'Statut patient : '}</b>
                                {consultation[elem].value}
                            </>
                        ),
                        key: elem,
                        children:
              consultation[elem].oxygenTherapyRequired === 'Oui'
                  ? [
                      {
                          title: (
                              <>
                                  <b>
                                      {'Nécessitant une oxygénothérapie à domicile : '}
                                  </b>
                                  {consultation[elem].oxygenTherapyRequired}
                              </>
                          ),
                          key: `0-${elem}`,
                      },
                      {
                          title: (
                              <>
                                  <b>{'Nombre de jours : '}</b>
                                  {consultation[elem].OxygenNumberOfDays}
                              </>
                          ),
                          key: `1-${elem}`,
                      },
                      {
                          title: (
                              <>
                                  <b>{'Nombre de litres : '}</b>
                                  {consultation[elem].OxygenNumberOfLiters}
                              </>
                          ),
                          key: `2-${elem}`,
                      },
                  ]
                  : [
                      {
                          title: (
                              <>
                                  <b>
                                      {'Nécessitant une oxygénothérapie à domicile : '}
                                  </b>
                                  {consultation[elem].oxygenTherapyRequired}
                              </>
                          ),
                          key: `4-${elem}`,
                      },
                  ],
                    });
                }
                if (consultation[elem].value === 'Décès') {
                    return res.push({
                        title: (
                            <>
                                <b>{'Statut patient : '}</b>
                                {consultation[elem].value}
                            </>
                        ),
                        key: elem,
                        children: [
                            {
                                title: (
                                    <>
                                        <b>{'Cause de décès : '}</b>
                                        {consultation[elem].causeOfDeath}
                                    </>
                                ),
                                key: `cause-${elem}`,
                            },
                        ],
                    });
                }
                return res.push({
                    title: (
                        <>
                            <b>{'Statut patient : '}</b>
                            {consultation[elem].value}
                        </>
                    ),
                    key: elem,
                });
            case 'treatments':
                return res.push({
                    title: (
                        <>
                            <b>{'Traitements : '}</b>
                        </>
                    ),
                    key: elem,
                    children: mapPrescription(consultation.treatments),
                });
            default:
                return res.push({
                    title: (
                        <>
                            <b>{`${keys[elem]} : `}</b>
                            {`${consultation[elem]}`}
                        </>
                    ),
                    key: elem,
                });
        }
    });
    return [
        {
            title: (
                <span>
                    <b className="m-right-small">{`${formatDateTimeFromIso(consultation.createdAt)}`}</b>
                    {prepareUserName(consultation)}
                </span>
            ),
            key: consultation._id,
            children: res,
        },
    ];
};

export default prepareTree;

/*
date
treatments
generalObservations
    barrierMeasures
    mask
    hygiene
    education
heartRate
respiratoryRate
oxygenSaturation
bodyTemperature
testPCR
bloodPressure

*/
