import React from 'react';
import {
    AppstoreTwoTone, ContactsTwoTone, HeartTwoTone, WarningTwoTone, MailTwoTone,
} from '@ant-design/icons';

const fontSize = 18;

export default [
    {
        name: 'Tableau de bord',
        icon: <AppstoreTwoTone twoToneColor="#c02433" style={{ fontSize }} />,
        route: '/',
    },
    {
        name: 'Patients en attente',
        icon: <HeartTwoTone twoToneColor="#c02433" style={{ fontSize }} />,
        route: 'patients',
    },
    {
        name: 'Patients suivis',
        icon: <ContactsTwoTone twoToneColor="#c02433" style={{ fontSize }} />,
        route: 'consultations',
    },
    {
        name: 'Evénements indésirables',
        icon: <WarningTwoTone twoToneColor="#c02433" style={{ fontSize }} />,
        route: 'adverse-events',
    },
    {
        name: 'Messagerie',
        icon: <MailTwoTone twoToneColor="#c02433" style={{ fontSize }} />,
        route: 'messagerie',
    },
];
