/* eslint-disable max-len */
/* eslint-disable no-underscore-dangle */
/* eslint-disable class-methods-use-this */
import React, { useState, useEffect } from 'react';
import { Card, Tabs } from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import Peer from 'peerjs';
import Contacts from './usersList';
import ConversationList from './conversationsList';
// import filter from './filter';
import ChatForm from './form';
import {
    setSelectUser,
    postMessageAsync,
    setUsersAsync,
    setConversationsListAsync,
    selectConversationAsync,
    updateConversationList,
    selectConversation,
    setConversationsList,
} from '../../redux/chat/actions';
// import { deleteMessageNotifs } from '../../redux/actions/notifications';
import './styles.css';

const { TabPane } = Tabs;

const Chat = ({
    setUsersAsync,
    setConversationsListAsync,
    selectedConversation,
    user,
    setSelectUser,
    conversations,
    updateConversationList,
    postMessageAsync,
    selectedUser,
    selectConversation,
    setConversationsList,
}) => {
    const [activeConnections, setActiveConnections] = useState('discussions');
    const [peer, setPeer] = useState();
    const [newMsg, setNewMSg] = useState();
    const [messages, setMessages] = useState([]);
    // const [updateActiveConnections, setUpdateActiveConnections] = useState(); // NOTE

    const removeActiveConnection = (peer) => {
        const newConnections = { ...activeConnections };
        delete newConnections[peer];
        setActiveConnections({ activeConnections: { ...newConnections } });
    };

    const scrollToBottom = () => {
        setTimeout(() => {
            if (document && selectedConversation) {
                const element = document.getElementById('messages-container');
                if (element) {
                    element.scrollTop = element.scrollHeight;
                }
            }
        }, 500);
    };

    useEffect(() => {
        if (newMsg) {
            const newMessages = [...selectedConversation.messages];
            newMessages.push(newMsg);
            setMessages(newMessages);
            scrollToBottom();
        }
    }, [newMsg]);

    useEffect(() => {
        const updatedSelectedConv = {
            ...selectedConversation,
            messages,
        };
        selectConversation({ ...updatedSelectedConv });
        scrollToBottom();
    }, [messages]);

    useEffect(() => {
        if (
            selectedConversation
      && selectedConversation.messages.length
      && !messages.length
        ) {
            setMessages([...selectedConversation.messages]);
        }
    }, [selectedConversation]);

    const handleReceiveData = (data, conn) => {
        const conv = conversations[conn.peer];
        if (conv) {
            const newConv = { ...conv };
            const msg = {
                sender: conn.peer,
                content: data,
                seen: false,
                time: Date.now(),
            };
            newConv.messages = [msg];
            updateConversationList(newConv);
            scrollToBottom();
            if (conv._id === selectedConversation._id) {
                setNewMSg(msg);
            }
        } else {
            setTimeout(() => {
                setConversationsListAsync();
            }, 500);
        }
    };

    // useEffect(() => { // NOTE
    //     if (updateActiveConnections) {
    //         const { value } = updateActiveConnections;
    //         if (updateActiveConnections.type === 'remove') {
    //             removeActiveConnection(value);
    //         }
    //         if (updateActiveConnections.type === 'add') {
    //             setActiveConnections({ ...activeConnections, [value.peer]: value });
    //             removeActiveConnection();
    //         }
    //     }
    // }, [updateActiveConnections]);

    const connectToPeer = () => {
        const peer = new Peer(user._id, { host: 'p2p.katomi.co', secure: true });
        setPeer(peer);
        peer.on('connection', (conn) => {
            conn.on('data', (data) => {
                handleReceiveData(data, conn);
            });

            conn.on('close', () => {
                removeActiveConnection(conn.peer);
            });
        });
    };

    const handleClickDiscussion = (conv) => {
        const _id = conv.user;
        setSelectUser({ _id, ...conv.user_info });
    };

    const handleClickContact = (contact) => {
        const { _id } = contact;
        setSelectUser({
            _id,
            firstName: contact.firstName,
            lastName: contact.lastName,
            speciality: contact.speciality,
        });
    };

    useEffect(() => {
        setUsersAsync();
        setConversationsListAsync();
        // if (unseenMessages.length) {
        //     // deleteMessageNotifs(unseenMessages);
        // }
        return () => {
            if (peer) {
                peer.destroy();
            }
            selectConversation(null);
            setSelectUser(null);
            setConversationsList([]);
        };
    }, []);

    useEffect(() => {
        if (user && !peer) {
            connectToPeer();
        }
    }, [user]);

    useEffect(() => {
        if (conversations.length) {
            const vals = Object.values(conversations);
            if (!selectedConversation && vals.length) {
                selectConversationAsync(vals[0]._id);
                scrollToBottom();
            }
        }
    }, [conversations]);

    const peerSendData = (remoteId, data) => {
        if (activeConnections[remoteId]) {
            // Sending the message to the existing connection
            activeConnections[remoteId].send(data);
        } else {
            // connecting to peer
            const conn = peer.connect(remoteId);
            if (conn) {
                conn.on('open', () => {
                    // connection opened
                    // setUpdateActiveConnections(conn); NOTE TRY THIS IF THE ACTUAL SOLUTION DOES NOT WORKS
                    setActiveConnections({ ...activeConnections, [conn.peer]: conn });
                    // sending the message through the opened connection
                    conn.send(data);

                    // listening to data from this connection
                    conn.on('data', (data) => {
                        handleReceiveData(data, conn);
                    });

                    conn.on('close', () => {
                        removeActiveConnection(conn.peer);
                    });
                });
            }
        }

        const msg = {
            content: data,
            sender: user._id,
            time: Date.now(),
        };
        if (
            conversations[remoteId]
      && conversations[remoteId]._id === selectedConversation._id
        ) {
            setNewMSg(msg);
        }
    };

    const handleSubmitMessage = (messageToSend) => {
        const data = selectedUser
            ? {
                content: messageToSend,
                time: Date.now(),
                u1: user._id,
                u2: selectedUser._id,
                u1_info: {
                    firstName: user.firstName,
                    lastName: user.lastName,
                    speciality: user.speciality,
                },
                u2_info: {
                    firstName: selectedUser.firstName,
                    lastName: selectedUser.lastName,
                },
            }
            : {
                content: messageToSend,
                time: Date.now(),
                u1: selectedConversation.u1,
                u2: selectedConversation.u2,
            };
        const receiver = data.u1 === user._id ? data.u2 : data.u1;
        // eslint-disable-next-line camelcase
        const receiver_info = data.u1 === user._id ? data.u2_info : data.u1_info;
        data.seen = false;
        const withSelectConv = conversations[receiver] === undefined;
        postMessageAsync(data, { receiver, receiver_info }, withSelectConv);
        peerSendData(receiver, messageToSend);
    };

    return (
        <div className="flex">
            <Card className="chat-tabs-container">
                <Tabs defaultActiveKey="discussions">
                    <TabPane
                        tab="Discussions"
                        key="discussions"
                        id="discussions"
                        ariaControls="discussions"
                    >
                        <ConversationList onClick={handleClickDiscussion} />
                    </TabPane>
                    <TabPane
                        tab="Contacts"
                        key="contacts"
                        id="contacts"
                        ariaControls="contacts"
                    >
                        <Contacts onSelect={handleClickContact} />
                    </TabPane>
                </Tabs>
            </Card>
            <div style={{ flex: 1 }}>
                <ChatForm onSubmitMessage={handleSubmitMessage} />
            </div>
        </div>
    );
};

Chat.propTypes = {
    setSelectUser: PropTypes.func.isRequired,
    postMessageAsync: PropTypes.func.isRequired,
    setUsersAsync: PropTypes.func.isRequired,
    setConversationsListAsync: PropTypes.func.isRequired,
    conversations: PropTypes.object.isRequired,
    selectedConversation: PropTypes.object,
    selectedUser: PropTypes.object,
    user: PropTypes.object.isRequired,
    updateConversationList: PropTypes.func.isRequired,
    selectConversation: PropTypes.func.isRequired,
    setConversationsList: PropTypes.func.isRequired,
    // deleteMessageNotifs: PropTypes.func.isRequired,
    // unseenMessages: PropTypes.array.isRequired,
};

Chat.defaultProps = {
    selectedConversation: null,
    selectedUser: null,
};

const stateToProps = state => ({
    selectedUser: state.chat.selectedUser,
    selectedConversation: state.chat.selectedConversation,
    conversations: state.chat.conversations,
    user: state.auth.user,
    // unseenMessages: state.notifications.unseenMessages,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setSelectUser,
        postMessageAsync,
        setUsersAsync,
        setConversationsListAsync,
        selectConversationAsync,
        updateConversationList,
        selectConversation,
        setConversationsList,
        // deleteMessageNotifs,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Chat);
