import axios from 'axios';
import { API_CHAT, API_REQ_HEADER } from './config';


const getUsers = async () => {
    const res = await axios.get(`${API_CHAT}/users`, API_REQ_HEADER);
    return res.data;
};

const getConversationById = async (id) => {
    const res = await axios.get(`${API_CHAT}/${id}`, API_REQ_HEADER);

    return res.data;
};


const getNumberOfUnseenMessages = async () => {
    const res = await axios.get(`${API_CHAT}/seen`, API_REQ_HEADER);

    return res.data;
};

const setSeenOnConversationById = async (id) => {
    const res = await axios.post(`${API_CHAT}/${id}`, null, API_REQ_HEADER);

    return res.data;
};

const getConversationByUsersIds = async (body) => {
    const res = await axios.post(`${API_CHAT}/`, body, API_REQ_HEADER);

    return res.data;
};

const postMessage = async (body) => {
    const res = await axios.post(`${API_CHAT}/msg`, body, API_REQ_HEADER);

    return res.data;
};

const getAllConversations = async () => {
    const res = await axios.get(`${API_CHAT}`, API_REQ_HEADER);

    return res.data;
};

export {
    getUsers,
    getConversationById,
    getConversationByUsersIds,
    postMessage,
    getAllConversations,
    setSeenOnConversationById,
    getNumberOfUnseenMessages,
};
