import { message } from 'antd';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';
import { updatePatient } from '../../patients/actions';
import * as ConsultationsAPI from '../../../api/consultations';

export const SET_CONSULTATION_LIST = 'SET_CONSULTATION_LIST';

export const setConsultationList = value => ({
    type: SET_CONSULTATION_LIST,
    payload: value,
});


export const createConsultation = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.create(body);
        dispatch(updatePatient(res.patient));
        message.success('Consultation créé avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const setConsultationListAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await ConsultationsAPI.getByPatient(id);
        dispatch(setConsultationList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
