/* eslint-disable no-underscore-dangle */
import React, { useEffect } from 'react';
import {
    Button, Table, Typography, Breadcrumb, Spin,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setPendingListAsync,
    setPatientsList,
    selectPatientAsync,
    selectPatient,
    setNurses,
} from '../../redux/patients/actions';
import { getFullName, formatDateTimeFromIso } from '../../helpers/functions';
import SelectedPatient from './selectedPatient';
import './styles.css';

const { Title } = Typography;

const Patients = ({
    patientsList,
    setPendingListAsync,
    setPatientsList,
    selectPatientAsync,
    selectPatient,
    selectedPatient,
    setNurses,
    contentIsLoading,
}) => {
    const columns = [
        {
            title: <b>Nom et Prénom </b>,
            key: 'name',
            render: (_, record) => <p>{getFullName(record)}</p>,
        },
        {
            title: <b> Date de création </b>,
            dataIndex: 'createdAt',
            key: 'createdAt',
            render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },
        {
            title: <b> Code patient </b>,
            dataIndex: '_id',
            key: '_id',
            // render: (_, record) => <p>{formatDateTimeFromIso(record.createdAt)}</p>,
        },
        {
            title: <b> Actions </b>,
            key: 'action',
            render: (_, record) => (
                <Button
                    type="link"
                    onClick={() => {
                        selectPatientAsync(record._id);
                    }}
                >
                    Démarrer la prise en charge
                </Button>
            ),
        },
    ];

    useEffect(() => {
        setPendingListAsync();
        return () => {
            setPatientsList([]);
            selectPatient(null);
            setNurses([]);
        };
    }, []);

    return (
        <div style={{ margin: '20px 50px' }}>
            <Spin spinning={contentIsLoading}>
                {selectedPatient ? (
                    <Breadcrumb style={{ marginBottom: 15 }}>
                        <Breadcrumb.Item onClick={() => selectPatient(null)}>
                            <Button style={{ padding: 0 }} type="link">
                                Patients en attente
                            </Button>
                        </Breadcrumb.Item>
                        <Breadcrumb.Item>{getFullName(selectedPatient)}</Breadcrumb.Item>
                    </Breadcrumb>
                ) : null}

                {selectedPatient ? (
                    <SelectedPatient />
                ) : (
                    <>
                        <Title level={2} style={{ color: '#c4313f' }}>
                            Patients en attente
                        </Title>
                        <Table dataSource={patientsList} columns={columns} locale={{ emptyText: 'Aucun patient' }} />
                    </>
                )}
            </Spin>

        </div>
    );
};

Patients.propTypes = {
    patientsList: PropTypes.array.isRequired,
    setPendingListAsync: PropTypes.func.isRequired,
    setPatientsList: PropTypes.func.isRequired,
    selectPatientAsync: PropTypes.func.isRequired,
    selectPatient: PropTypes.func.isRequired,
    setNurses: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object,
    contentIsLoading: PropTypes.bool.isRequired,
};

Patients.defaultProps = {
    selectedPatient: null,
};

const stateToProps = state => ({
    patientsList: state.patients.patientsList,
    selectedPatient: state.patients.selectedPatient,
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setPendingListAsync,
        setPatientsList,
        selectPatientAsync,
        selectPatient,
        setNurses,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Patients);
