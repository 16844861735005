/* eslint-disable no-underscore-dangle */
import React from 'react';
import { List, Avatar, Divider } from 'antd';
import { connect } from 'react-redux';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { getFullName, getUserInitials } from '../../helpers/functions';
import './styles.css';

const getClassNames = isSelected => classnames({
    'selected-contact': isSelected,
});

const UsersList = ({ users, onSelect, selectedUser }) => (
    <div>
        <h4 style={{ marginLeft: 15 }}>
            <strong> Médecins </strong>
        </h4>
        <List
            className="contacts-list"
            // header="Médecins"
            itemLayout="horizontal"
            dataSource={users.doctors}
            renderItem={item => (
                <List.Item
                    className={getClassNames(
                        selectedUser ? item._id === selectedUser._id : false,
                    )}
                    onClick={() => onSelect(item)}
                >
                    <List.Item.Meta
                        avatar={(
                            <Avatar style={{ backgroundColor: '#c4313f' }}>
                                {getUserInitials(item)}
                            </Avatar>
                        )}
                        title={getFullName(item)}
                        description={`Médecin de ${item.governorate}`}
                    />
                </List.Item>
            )}
        />

        <Divider style={{ margin: '0 0 10px' }} />
        <h4 style={{ marginLeft: 15 }}>
            <strong> Infirmiers </strong>
        </h4>

        <List
            className="contacts-list"
            // header=""
            itemLayout="horizontal"
            dataSource={users.nurses}
            renderItem={item => (
                <List.Item
                    className={getClassNames(
                        selectedUser ? item._id === selectedUser._id : false,
                    )}
                    onClick={() => onSelect(item)}
                >
                    <List.Item.Meta
                        avatar={(
                            <Avatar style={{ backgroundColor: '#c4313f' }}>
                                {getUserInitials(item)}
                            </Avatar>
                        )}
                        title={getFullName(item)}
                        description={
                            item.governorate ? `Infirmier de ${item.governorate}` : ''
                        }
                    />
                </List.Item>
            )}
        />
    </div>
);

const stateToProps = state => ({
    users: state.chat.users,
    selectedUser: state.chat.selectedUser,
    // unseenMessages: state.notifications.unseenMessages,
});

UsersList.propTypes = {
    users: PropTypes.object.isRequired,
    onSelect: PropTypes.func.isRequired,
    selectedUser: PropTypes.object,
};

UsersList.defaultProps = {
    selectedUser: null,
};

export default connect(stateToProps)(UsersList);
