import React, { useState } from 'react';
import {
    Form, Radio, Input, Typography, Button,
} from 'antd';
import PropTypes from 'prop-types';
import FORM_VALIDATORS from '../../../helpers/formRules';

const { Title } = Typography;

const PatientStatus = ({ handleNext }) => {
    const [statusValue, setStatusValue] = useState();
    const [extraRadioValue, setExtraRadioValue] = useState();
    const [form] = Form.useForm();

    const handleStatusChange = (value) => {
        setExtraRadioValue(null);
        form.setFieldsValue({ oxygenTherapyRequired: null, causeOfDeath: null });
        setStatusValue(value);
    };

    const handleChangeExtraValue = (key, value) => {
        setExtraRadioValue(value);
        form.setFieldsValue({ [key]: value });
    };

    const onFinish = (values) => {
        const body = { ...values };
        if (body.value === 'Décès' && extraRadioValue === 'Autre') {
            body.causeOfDeath = body.otherCauseOfDeath;
            delete body.otherCauseOfDeath;
        }
        handleNext('patientStatus', body);
        // NOTE don't forget to update field patient status either from FE or BE
    };

    return (
        <Form className="patient-status-form" form={form} onFinish={onFinish}>
            <Form.Item
                rules={[FORM_VALIDATORS.required]}
                name="value"
                className="flex-column"
                label={
                    <Title level={5}> Statut du patient à fin de la consultation </Title>
                }
            >
                <Radio.Group
                    className="flex-column"
                    value={statusValue}
                    onChange={e => handleStatusChange(e.target.value)}
                >
                    <Radio style={{ marginBottom: 14 }} value="Stable">
                        Stable
                    </Radio>
                    <Radio style={{ marginBottom: 14 }} value="Amélioration">
                        Amélioration
                    </Radio>
                    <Radio style={{ marginBottom: 14 }} value="Aggravation">
                        Aggravation
                    </Radio>
                    {statusValue === 'Aggravation' ? (
                        <Form.Item
                            rules={[FORM_VALIDATORS.required]}
                            name="oxygenTherapyRequired"
                            label="Nécessitant une oxygénothérapie à domicile"
                            style={{ marginLeft: 30 }}
                        >
                            <Radio.Group
                                value={extraRadioValue}
                                onChange={e => handleChangeExtraValue('oxygenTherapyRequired', e.target.value)}
                            >
                                <Radio value="Oui">Oui</Radio>
                                <Radio value="Non"> Non</Radio>
                            </Radio.Group>
                            {extraRadioValue === 'Oui' ? (
                                <div style={{ marginTop: 14 }}>
                                    <Form.Item
                                        rules={[FORM_VALIDATORS.required]}
                                        name="OxygenNumberOfDays"
                                        label="Nombre de jours"
                                    >
                                        <Input />
                                    </Form.Item>
                                    <Form.Item
                                        rules={[FORM_VALIDATORS.required]}
                                        name="OxygenNumberOfLiters"
                                        label="Nombre de litres"
                                    >
                                        <Input />
                                    </Form.Item>
                                </div>
                            ) : null}
                        </Form.Item>
                    ) : null}

                    <Radio style={{ marginBottom: 14 }} value="Guérison">
                        Guérison
                    </Radio>

                    <Radio
                        style={{ marginBottom: 14 }}
                        value="Transfert vers un établissement de soin"
                    >
                        Transfert vers un établissement de soin
                    </Radio>
                    <Radio style={{ marginBottom: 14 }} value="Décès">
                        Décès
                    </Radio>
                    {statusValue === 'Décès' ? (
                        <Form.Item
                            rules={[FORM_VALIDATORS.required]}
                            name="causeOfDeath"
                            label="Cause de décès"
                            style={{ marginLeft: 30 }}
                        >
                            <Radio.Group
                                value={extraRadioValue}
                                onChange={e => handleChangeExtraValue('causeOfDeath', e.target.value)}
                            >
                                <Radio value="COVID19">COVID19</Radio>
                                <Radio value="Autre"> Autre</Radio>
                            </Radio.Group>
                            {extraRadioValue === 'Autre' ? (
                                <div style={{ marginTop: 14 }}>
                                    <Form.Item
                                        name="otherCauseOfDeath"
                                        rules={[FORM_VALIDATORS.required]}
                                    >
                                        <Input placeholder="Cause de décès" />
                                    </Form.Item>
                                </div>
                            ) : null}
                        </Form.Item>
                    ) : null}
                    <Radio style={{ marginBottom: 14 }} value="Soins palliatifs">
                        Soins palliatifs
                    </Radio>
                </Radio.Group>
            </Form.Item>

            <Form.Item>
                <Button htmlType="submit" shape="round" type="primary">
                    Terminer
                </Button>
            </Form.Item>
        </Form>
    );
};

PatientStatus.propTypes = {
    handleNext: PropTypes.func.isRequired,
};

export default PatientStatus;
