import { message } from 'antd';
import * as PatientsAPI from '../../../api/patients';
import { addImages, deleteImage } from '../../../api/images';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';

export const SET_PATIENTS_LIST = 'SET_PATIENTS_LIST';

export const setPatientsList = value => ({
    type: SET_PATIENTS_LIST,
    payload: value,
});

export const SET_PATIENT = 'SET_PATIENT';
export const SET_NURSES = 'SET_NURSES';
export const UPDATE_PATIENT = 'UPDATE_PATIENT';

export const selectPatient = value => ({
    type: SET_PATIENT,
    payload: value,
});

export const setNurses = value => ({
    type: SET_NURSES,
    payload: value,
});


export const updatePatient = value => ({
    type: UPDATE_PATIENT,
    payload: value,
});


export const setOnboardedListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getOnboarded();
        dispatch(setPatientsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updateOnboardedStatus = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.setOnboardedStatus(body);
        if (res.nurses.length) {
            dispatch(setNurses(res.nurses));
        }
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};

export const setPendingListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getPending();
        dispatch(setPatientsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const selectPatientAsync = id => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.getById(id);
        dispatch(selectPatient(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const assignToNurseAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        await PatientsAPI.assignToNurse(body);
        message.success('infirmier(e) affecté(e) avec succès');
        dispatch(selectPatient(null));
        dispatch(setNurses([]));
        dispatch(setPendingListAsync());
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updatePatientAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await PatientsAPI.update(id, body);
        dispatch(selectPatient(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const uploadImageAsync = (userId, category, image) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await addImages(userId, category, image);
        dispatch(selectPatient(res.data));
        message.success('Image ajoutée avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const deleteImageAsync = (userId, category, filename) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await deleteImage(userId, category, filename);
        dispatch(selectPatient(res.data));
        message.success('Image supprimée avec succès');
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
