/* eslint-disable max-len */
/* eslint-disable react/jsx-indent-props */
/* eslint-disable indent */
import React, { useEffect } from 'react';
import { Card, Typography, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
    Column, Bar, Pie, Area,
} from '@ant-design/charts';
import {
    setDashBoardStatsAsync,
    setFullPatientsList,
} from '../../redux/stats/actions';
import PatientsList from './patientsList';
import './styles.css';

const { Title, Text } = Typography;

const patientsByGenderConfig = data => ({
    isGroup: true,
    data,
    height: 400,
    xField: 'month',
    yField: 'count',
    seriesField: 'gender',
    label: {
        position: 'middle', // 'top', 'bottom', 'middle',
        layout: [
            { type: 'interval-adjust-position' },
            { type: 'interval-hide-overlap' },
            { type: 'adjust-color' },
        ],
    },
    meta: {
        month: {
            alias: 'Mois',
        },
        value: {
            alias: 'Nombre de patients pris en charge par Demo',
        },
    },
});

const patientsByAgeConfig = data => ({
    data,
    xField: 'count',
    yField: 'age',
    // seriesField: 'age',
    yAxis: {
    // format y axis label style
        label: {
            formatter: v => `age: ${v}`,
        },
    },
    legend: false,
    meta: {
        count: {
            alias: 'nombre de patients',
        },
        age: {
            alias: 'age des patients',
        },
    },
});

const pieConfig = (data, key) => ({
    appendPadding: 10,
    data,
    angleField: 'count',
    colorField: key,
    radius: 0.9,
    label: {
        type: 'inner',
        offset: '-30%',
        content: ({ percent }) => `${(percent * 100).toFixed(0)}%`,
        style: {
            fontSize: 14,
            textAlign: 'center',
        },
    },
    interactions: [{ status: 'element-active' }],
});


const patientsByMonthConfig = data => ({
    data,
    xField: 'month',
    yField: 'count',
    meta: {
        count: {
            alias: 'nombre de patients',
        },
        month: {
            alias: 'Mois',
        },
    },
    xAxis: {
        range: [0, 1],
        tickCount: 5,
    },
});

const Dashboard = ({
    dashboardStats,
    setDashBoardStatsAsync,
    contentIsLoading,
    setFullPatientsList,
    user,
}) => {
    useEffect(() => {
        if (user && user.type === 3) {
            setFullPatientsList();
        }
        setDashBoardStatsAsync();
    }, []);

    if (!dashboardStats) return <Spin spinning={contentIsLoading} />;
    console.log(dashboardStats.patientsByMonth);
    console.log(dashboardStats.patientsByGender);
    return (
        <div className="flex-column">
            <Spin spinning={contentIsLoading}>
                {user && user.type === 3 ? (
                    <PatientsList />
                ) : (
                    <div
                        className="flex dashboard-container"
                        style={{ marginBottom: 60 }}
                    >
                        <Card className="dashboard-card">
                            <i className="dashboard-card-icon fal fa-3x fa-syringe" />
                            <div className="dashboard-card-info">
                                <Title level={5}>En attente </Title>
                                <Text style={{ color: '#c4313f', fontSize: 18 }}>
                                    {dashboardStats.pendingPatientsCount}
                                </Text>
                            </div>
                        </Card>
                        <Card className="dashboard-card">
                            <i className="dashboard-card-icon fa-3x fal fa-stethoscope" />
                            <div className="dashboard-card-info">
                                <Title level={5}> Suivis</Title>
                                <Text style={{ color: '#c4313f', fontSize: 18 }}>
                                    {dashboardStats.patientsByStatus['En cours de suivi'] || 0}
                                </Text>
                            </div>
                        </Card>

                        <Card className="dashboard-card">
                            <i className="dashboard-card-icon fal fa-3x fa-laptop-medical" />
                            <div className="dashboard-card-info">
                                <Title level={5} style={{ whiteSpace: 'nowrap' }}>
                                    Hospitalisé
                                </Title>
                                <Text style={{ color: '#c4313f', fontSize: 18 }}>
                                    {dashboardStats.patientsByStatus['Hospitalisé'] || 0}
                                </Text>
                            </div>
                        </Card>
                        <Card className="dashboard-card">
                            <i className="dashboard-card-icon fal fa-3x fa-users-medical" />
                            <div className="dashboard-card-info">
                                <Title level={5}> Guéris</Title>
                                <Text style={{ color: '#c4313f', fontSize: 18 }}>
                                    {dashboardStats.patientsByStatus['Guéri'] || 0}
                                </Text>
                            </div>
                        </Card>
                    </div>
                )}
                <div
                    className="flex flex-wrap"
                    style={{ justifyContent: 'space-between' }}
                >
                    {user
          && user.type === 3
          && dashboardStats.patientsByGovernorate.length ? (
              <Card
                  title="Répartition des patients par gouvernorat"
                  style={{ width: '49.2%', margin: '24px 0' }}
                            >
                  <Pie
                                    {...pieConfig(dashboardStats.patientsByGovernorate, 'governorate')}
                                />
              </Card>
                        ) : null}

                    {dashboardStats.patientsByGender.length ? (
                        <Card
                            title="Répartition des patients par sexe"
                            style={{ width: '49.2%', margin: '24px 0' }}
                        >
                            <Column
                                {...patientsByGenderConfig(dashboardStats.patientsByGender)}
                            />
                        </Card>
                    ) : null}
                    {dashboardStats.eventsByStatus.length && user.type !== 3 ? (
                        <Card
                            title="Evénements indésirables en fonction de l'étiologie"
                            style={{ width: '49.2%', margin: '24px 0' }}
                        >
                            <Pie {...pieConfig(dashboardStats.eventsByStatus, 'status')} />
                        </Card>
                    ) : null}

                    {dashboardStats.patientsByMonth.length ? (
                        <Card
                            title="Nombre de patients par mois"
                            style={{ width: '49.2%', margin: '24px 0' }}
                        >
                            <Area
                            {...patientsByMonthConfig(dashboardStats.patientsByMonth)}
                            />
                        </Card>
                    ) : null}
                    {dashboardStats.patientsByAge.length ? (
                        <Card
                            title="Répartition des patients par age"
                            style={{ width: '49.2%', margin: '24px 0' }}
                        >
                            <Bar {...patientsByAgeConfig(dashboardStats.patientsByAge)} />
                        </Card>
                    ) : null}
                </div>
            </Spin>
        </div>
    );
};

Dashboard.propTypes = {
    dashboardStats: PropTypes.object.isRequired,
    setDashBoardStatsAsync: PropTypes.func.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    setFullPatientsList: PropTypes.func.isRequired,
    user: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    dashboardStats: state.stats.dashboardStats,
    contentIsLoading: state.loading.contentIsLoading,
    user: state.auth.user,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setDashBoardStatsAsync,
        setFullPatientsList,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(Dashboard);
