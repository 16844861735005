/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
import React from 'react';
import {
    Form, Radio, InputNumber, Typography, Button,
} from 'antd';
import PropTypes from 'prop-types';

const { Text } = Typography;

const GeneralObservations = ({ handleNext }) => {
    const onFinish = (values) => {
        const keys = Object.keys(values);
        const obj = {};
        keys.map((key) => {
            if (key !== 'familyNumber') {
                const splittedKey = key.split('-');

                if (!obj[splittedKey[0]]) {
                    obj[splittedKey[0]] = {};
                }
                obj[splittedKey[0]][splittedKey[1]] = values[key];
                return obj;
            }
            obj[key] = values[key];
        });
        handleNext('generalObservations', obj);
    };

    return (
        <Form onFinish={onFinish}>
            <Form.Item
                label={(
                    <Text strong>
                        {' '}
                        Nombre des membres de famille vivant sous le même toit
                    </Text>
                )}
                name="familyNumber"

            >
                <InputNumber />
            </Form.Item>
            <Form.Item label={<Text strong> Mesures barrières </Text>}>
                <Form.Item
                    label={<Text style={{ color: '#1890ff' }}>Rappelées </Text>}
                    name="barrierMeasures-Rappelées"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={<Text type="success"> Observées sont respectés</Text>}
                    name="barrierMeasures-Observées"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form.Item>

            <Form.Item label={<Text strong> Port du masque</Text>}>
                <Form.Item
                    label={<Text style={{ color: '#1890ff' }}>Rappelées </Text>}
                    name="mask-Rappelées"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={(
                        <Text type="success">
                            Respecté au sein de l’environnement familial
                        </Text>
                    )}
                    name="mask-Respecté"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form.Item>

            <Form.Item
                label={(
                    <Text strong>
                        Hygiène des mains et l’utilisation de gel hydroalcoolique
                    </Text>
                )}
            >
                <Form.Item
                    label={<Text style={{ color: '#1890ff' }}>Rappelées </Text>}
                    name="hygiene-Rappelées"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={(
                        <Text type="success">
                            Respecté au sein de l’environnement familial
                        </Text>
                    )}
                    name="hygiene-Respecté"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form.Item>

            <Form.Item
                label={<Text strong> Education thérapeutique / Prévention</Text>}
            >
                <Form.Item
                    label={<Text style={{ color: '#1890ff' }}>Rappelées </Text>}
                    name="education-Rappelées"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={<Text type="success">Observées sont respectés</Text>}
                    name="education-Observées"

                >
                    <Radio.Group>
                        <Radio value="Oui">Oui</Radio>
                        <Radio value="Non">Non</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form.Item>

            <Form.Item>
                <Button
                    htmlType="submit"
                    className="center-button"
                    shape="round"
                    type="primary"
                >
                    Suivant
                </Button>
            </Form.Item>
        </Form>
    );
};

GeneralObservations.propTypes = {
    handleNext: PropTypes.func.isRequired,
};

export default GeneralObservations;
