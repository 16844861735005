import React from 'react';
import { DatePicker, ConfigProvider } from 'antd';
import moment from 'moment';
import 'moment/locale/fr';
import fr from 'antd/es/locale/fr_FR';

moment.locale('fr');

const CustomDatePicker = props => (
    <ConfigProvider locale={fr}>
        <DatePicker {...props} />
    </ConfigProvider>
);


export default CustomDatePicker;
