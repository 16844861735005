import * as AdverseEventsAPI from '../../../api/adverseEvents';
import { setContentIsLoading } from '../../loading/actions';
import { sessionExpired } from '../../auth/actions';


export const SET_EVENTS_LIST = 'SET_EVENTS_LIST';
export const SET_EVENT = 'SET_EVENT';
export const PUSH_EVENTS_LIST = 'PUSH_EVENTS_LIST';
export const UPDATE_EVENT = 'UPDATE_EVENT';

export const setEventsList = value => ({
    type: SET_EVENTS_LIST,
    payload: value,
});


export const selectEvent = value => ({
    type: SET_EVENT,
    payload: value,
});


export const pushToEventsList = value => ({
    type: PUSH_EVENTS_LIST,
    payload: value,
});

export const updateEvent = value => ({
    type: UPDATE_EVENT,
    payload: value,
});

export const setFullListAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseEventsAPI.getAll();
        dispatch(setEventsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const createEventAsync = body => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseEventsAPI.create(body);
        dispatch(pushToEventsList(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const updateEventAsync = (id, body) => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await AdverseEventsAPI.update(id, body);
        dispatch(updateEvent(res.data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
