import React from 'react';
import { Breadcrumb, Button } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFullName } from '../../helpers/functions';

const HeadBreadCrumb = ({ handleGoBack, sectionName, selectedPatient }) => (
    <Breadcrumb style={{ marginBottom: 15 }}>
        <Breadcrumb.Item onClick={() => handleGoBack()}>
            <Button style={{ padding: 0 }} type="link">
                Patients suivis
            </Button>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{getFullName(selectedPatient)}</Breadcrumb.Item>
        <Breadcrumb.Item>{sectionName}</Breadcrumb.Item>
    </Breadcrumb>
);


HeadBreadCrumb.propTypes = {
    handleGoBack: PropTypes.func.isRequired,
    sectionName: PropTypes.string.isRequired,
    selectedPatient: PropTypes.object.isRequired,
};


const stateToProps = state => ({
    selectedPatient: state.patients.selectedPatient,
});


export default connect(stateToProps)(HeadBreadCrumb);
