/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { Steps } from 'antd';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { createConsultation } from '../../../redux/consultations/actions';
import { selectPatient } from '../../../redux/patients/actions';
import ConsultationInformation from './consultationInformation';
import ClinicalExamination from './clinicalExamination';
import AddPrescription from './prescription';
import PatientStatus from './patientStatus';
import GeneralObservations from './generalObservations';

const { Step } = Steps;

const AddConsultation = ({
    createConsultation,
    selectPatient,
    selectedPatient,
    handleCloseAdd,
}) => {
    const [current, setCurrent] = useState(0);
    const [payload, setPayload] = useState({});

    const handlePayloadChange = (key, value) => {
        setPayload({ ...payload, [key]: value });
    };

    const setNextStep = (key, value) => {
        if (current < 4) {
            handlePayloadChange(key, value);
            setCurrent(current + 1);
        } else {
            const body = {
                ...payload,
                ...payload.clinicalExamination,
                patient: selectedPatient._id,
                [key]: value,
            };
            createConsultation(body);
            selectPatient(null);
            handleCloseAdd();
        }
    };

    useEffect(
        () => () => {
            selectPatient(null);
        },
        [],
    );

    const showStep = () => {
        switch (current) {
            case 0:
                return <ConsultationInformation handleNext={setNextStep} />;
            case 1:
                return <ClinicalExamination handleNext={setNextStep} />;
            case 2:
                return <AddPrescription handleNext={setNextStep} />;
            case 3:
                return <GeneralObservations handleNext={setNextStep} />;
            case 4:
                return <PatientStatus handleNext={setNextStep} />;

            default:
                return <div />;
        }
    };

    return (
        <div className="flex-column" style={{ margin: '50px 30px 30px' }}>
            <Steps current={current}>
                <Step title="Type de consultation" />
                <Step title="Examen Clinique" />
                <Step title="Prescription" />
                <Step title="Observations générales" />
                <Step title="Statut du patient" />
            </Steps>
            <div style={{ margin: '24px auto' }}>{showStep()}</div>
        </div>
    );
};

AddConsultation.propTypes = {
    createConsultation: PropTypes.func.isRequired,
    selectPatient: PropTypes.func.isRequired,
    selectedPatient: PropTypes.object.isRequired,
    handleCloseAdd: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    selectedPatient: state.patients.selectedPatient,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        createConsultation,
        selectPatient,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(AddConsultation);
