import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tree, Card, Typography } from 'antd';
import { generalInfoTree, symptomatologyTree, generalMedicalTree } from '../../helpers/patients';

const { Title } = Typography;

const GeneralInfo = ({ selectedPatient }) => {
    const generalInfoTreeData = generalInfoTree(selectedPatient);
    const symptomatologyTreeData = symptomatologyTree(selectedPatient.symptomatology);
    const generalMedicalTreeData = generalMedicalTree(selectedPatient);

    return (
        <div className="flex-column general-info-container">
            <Title level={4} style={{ marginBottom: 14, textAlign: 'center' }}>informations collectées par le centre d&apos;appel Demo</Title>

            <div className="flex">
                <Card title="Informations Générales" className="general-info-card">
                    <Tree treeData={generalInfoTreeData} defaultExpandedKeys={['full-name', 'gender']} />
                </Card>
                <Card title="Symptomatologie" className="general-info-card">
                    <Tree treeData={symptomatologyTreeData} defaultExpandedKeys={['symptomatology', 'contactWithAnotherCase']} />
                </Card>
                <Card title="Scoring" className="general-info-card">
                    <Tree treeData={generalMedicalTreeData} defaultExpandedKeys={['general-medical', 'testPCR']} />
                </Card>

            </div>
        </div>
    );
};

GeneralInfo.propTypes = {
    selectedPatient: PropTypes.object.isRequired,
};

const stateToProps = state => ({
    patientsList: state.patients.patientsList,
    selectedPatient: state.patients.selectedPatient,
});

export default connect(stateToProps)(GeneralInfo);
