import React, { useState } from 'react';
import {
    Radio, Typography, Form, InputNumber, Button,
} from 'antd';
import PropTypes from 'prop-types';
import FORM_VALIDATORS from '../../../helpers/formRules';

const { Title, Text } = Typography;

const ConsultationInformation = ({ handleNext }) => {
    const [radioValue, setRadioValue] = useState();
    const [payed, setPayed] = useState();

    const onFinish = (values) => {
        handleNext('consultationType', values);
    };

    return (
        <Form onFinish={onFinish} className="consultation-type-form">
            <Title level={4}> Veuillez indiquer le type de la consultation </Title>
            <Form.Item name="value" rules={[FORM_VALIDATORS.required]}>
                <Radio.Group className="flex-column" onChange={e => setRadioValue(e.target.value)} value={radioValue}>
                    <Radio value="Physique">Consultation Physique</Radio>
                    {radioValue === 'Physique' ? (
                        <Form.Item
                            style={{ marginTop: 14 }}
                            label={<Text strong> Payée? </Text>}
                            name="payed"
                            rules={[FORM_VALIDATORS.required]}
                        >
                            <Radio.Group onChange={e => setPayed(e.target.value)}>
                                <Radio value="Oui">Oui</Radio>
                                <Radio value="Non">Non</Radio>
                                {payed === 'Oui' ? (
                                    <Form.Item
                                        style={{ marginTop: 14 }}
                                        label="Montant payé (DT)"
                                        name="amount"
                                        rules={[FORM_VALIDATORS.required]}
                                    >
                                        <InputNumber min={0} />
                                    </Form.Item>
                                ) : null}
                            </Radio.Group>
                        </Form.Item>
                    ) : null}
                    <Radio value="Téléphonique">Consultation Téléphonique</Radio>

                </Radio.Group>
            </Form.Item>

            <Form.Item>
                <Button
                    htmlType="submit"
                    shape="round"
                    type="primary"
                    className="center-button"
                >
                    Suivant
                </Button>
            </Form.Item>
        </Form>
    );
};
ConsultationInformation.propTypes = {
    handleNext: PropTypes.func.isRequired,
};

export default ConsultationInformation;
