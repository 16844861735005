/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Upload, Button } from 'antd';
import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import PropTypes from 'prop-types';


const UploadImage = ({
    showDelete, onDelete, onUpload,
}) => (
    <div className="flex" style={{ justifyContent: 'space-around' }}>
        {showDelete ? (
            <Button
                type="danger"
                size="large"
                style={{ marginTop: 10 }}
                onClick={() => onDelete()}
            >
                <DeleteOutlined style={{ marginRight: 5 }} />
                Supprimer cette image
            </Button>
        ) : null}
        <Upload
            showUploadList={false}
            customRequest={({ file }) => {
                setTimeout(() => {
                    onUpload(file);
                }, 0);
            }}
            listType="text"
        >
            <Button block type="primary" size="large" style={{ marginTop: 10 }}>
                <UploadOutlined style={{ marginRight: 5 }} />
                Télécharger une image
            </Button>
        </Upload>
    </div>
);

UploadImage.propTypes = {
    showDelete: PropTypes.bool.isRequired,
    onUpload: PropTypes.func.isRequired,
    onDelete: PropTypes.func,
};


UploadImage.defaultProps = {
    onDelete: () => {},
};


export default (UploadImage);
