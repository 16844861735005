/* eslint-disable no-case-declarations */
/* eslint-disable no-underscore-dangle */
/* eslint-disable no-restricted-syntax */
import {
    SELECT_CONVERSATION,
    SELECT_USER,
    SET_CONVERSATIONS,
    UPDATE_CONVERSATION,
    SET_USERS,
    ADD_CONVERSATION,
} from '../actions';

const initialState = {
    selectedConversation: null,
    selectedUser: null,
    conversations: {},
    users: {
        doctors: [],
        nurses: [],
    },
};

const chat = (state = initialState, action) => {
    switch (action.type) {
        case SELECT_CONVERSATION:
            return { ...state, selectedConversation: action.payload };
        case ADD_CONVERSATION:
            return { ...state, conversations: { ...action.payload, ...state.conversations } };
        case SELECT_USER:
            return {
                ...state,
                selectedUser: action.payload,
                selectedConversation: null,
            };
        case SET_CONVERSATIONS:
            return { ...state, conversations: action.payload };
        case UPDATE_CONVERSATION:
            const keys = Object.keys(state.conversations);
            const values = Object.values(state.conversations);
            const newVals = [];
            values.map((val) => {
                if (val._id === action.payload._id) {
                    return newVals.push(action.payload);
                }
                return newVals.push(val);
            });

            const conversations = keys.reduce(
                (obj, key, index) => ({ ...obj, [key]: newVals[index] }),
                {},
            );

            return {
                ...state,
                conversations,
            };

        case SET_USERS:
            return { ...state, users: action.payload };
        default:
            return state;
    }
};

export default chat;
