/* eslint-disable no-console */
import React from 'react';
import { Layout, Spin } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import history from '../history';
import AppHeader from '../components/AppHeader';
import { checkAuthAsync } from '../redux/auth/actions';
import './styles.css';
import Sidebar from '../components/Sidebar';
// import Login from '../sections/Login';
import Routes from '../routes';

const { Footer, Content } = Layout;
const resolveCurrentUrl = () => history.location.pathname.split('/')[1] || '/';
const isLoginRoute = () => resolveCurrentUrl() === 'login';

const showSidebar = !isLoginRoute();
class App extends React.Component {
  state = {
      selectedItem: resolveCurrentUrl(),
  };

  componentDidMount() {
      const { checkAuthAsync, user } = this.props;
      checkAuthAsync();
      if (showSidebar && !user) {
          history.replace('/login');
      }

      history.listen(() => {
          if (!isLoginRoute()) {
              this.setState({
                  selectedItem: resolveCurrentUrl(),
              });
          }
      });
  }

  componentDidUpdate(props) {
      const { user } = this.props;

      if (props.user !== user) {
          this.authCheck();
      }
  }

  authCheck = () => {
      const { user } = this.props;
      const current = resolveCurrentUrl();
      if (user && current === 'login') {
          history.replace('/');
          //   this.forceUpdate();
      }

      if (!user && current !== 'login') {
          history.replace('/login');
          //   this.forceUpdate();
      }
      //   this.forceUpdate();
  };

  handleClickItem = (route) => {
      history.push(route);
      this.forceUpdate();
  };

  render() {
      const { selectedItem } = this.state;
      const { user, appIsLoading } = this.props;

      if (appIsLoading) {
          return (
              <div style={{ width: '100vw', height: '100vh' }}>
                  <Spin
                      spinning
                      size="large"
                      style={{ margin: '20% auto', display: 'block' }}
                  />
              </div>
          );
      }

      return (
          <>
              {user ? (
                  <Layout>
                      <AppHeader />
                      <Layout>
                          {user.type !== 3 ? (
                              <Sidebar selectedItem={selectedItem} onClick={this.handleClickItem} />
                          ) : null}
                          <Layout>
                              <Content className="app-content">
                                  <Routes />
                              </Content>
                              <Footer style={{ textAlign: 'center' }}>
                                  All rights reserved to Katomi ©2020
                              </Footer>
                          </Layout>
                      </Layout>
                  </Layout>
              ) : (
                  <Layout className="auth-layout">
                      <Content>
                          <Routes />
                      </Content>
                  </Layout>
              )}
          </>
      );
  }
}

App.propTypes = {
    user: PropTypes.object,
    checkAuthAsync: PropTypes.func.isRequired,
    appIsLoading: PropTypes.bool.isRequired,
};

App.defaultProps = {
    user: null,
};

const stateToProps = state => ({
    user: state.auth.user,
    appIsLoading: state.loading.appIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        checkAuthAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(App);
