import * as StatsAPI from '../../../api/stats';
import { getAllPatients } from '../../../api/patients';
import { sessionExpired } from '../../auth/actions';
import { setContentIsLoading } from '../../loading/actions';

export const SET_STATS = 'SET_STATS';
export const SET_PATIENTS = 'SET_PATIENTS';

export const setStats = value => ({
    type: SET_STATS,
    payload: value,
});


export const setPatients = value => ({
    type: SET_PATIENTS,
    payload: value,
});


const reverseArr = (arr) => {
    const res = [];
    while (arr[arr.length - 1].month === 'Decembre') {
        arr.unshift(arr.pop());
    }
    arr.forEach((e) => {
        res.push(e);
    });
    return res;
};

const reverseArrMounths = (arr) => {
    const res = [];
    arr.unshift(arr.pop());
    arr.forEach((e) => {
        res.push(e);
    });

    return res;
};


export const setDashBoardStatsAsync = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await StatsAPI.getDashboardStats();
        const { data } = res;
        const stats = {
            ...data,
            // NOTE THIS IS TEMPORARY
            patientsByMonth: reverseArrMounths(data.patientsByMonth),
            patientsByGender: reverseArr(data.patientsByGender)
            ,
        };
        dispatch(setStats(stats));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};


export const setFullPatientsList = () => async (dispatch) => {
    dispatch(setContentIsLoading(true));
    try {
        const res = await getAllPatients();
        const { data } = res;
        dispatch(setPatients(data));
    } catch (err) {
        sessionExpired(err, dispatch);
    }
    dispatch(setContentIsLoading(false));
};
