/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    Radio, Typography, Spin, Result, Button,
} from 'antd';
import { Line } from '@ant-design/charts';
import { formatDateTimeFromIso } from '../../helpers/functions';
import { selectPatient } from '../../redux/patients/actions';

const { Title } = Typography;

const keys = {
    heartRate: 'Fréquence cardiaque (BPM)',
    respiratoryRate: 'Fréquence respiratoire (CPM)',
    oxygenSaturation: 'Sat O2 (%)',
    bodyTemperature: 'Température (°C)',
};

const PatientStats = ({ consultationList, contentIsLoading, selectPatient }) => {
    const [radioValue, setRadioValue] = useState('heartRate');
    const [data, setData] = useState([]);

    useEffect(() => {
        if (consultationList.length) {
            const newData = [];
            consultationList.map(consult => newData.push({
                date: consult.createdAt,
                oxygenSaturation: consult.oxygenSaturation.value,
                heartRate: consult.heartRate,
                respiratoryRate: consult.respiratoryRate,
                bodyTemperature: consult.bodyTemperature,
            }));
            setData(newData);
        }
    }, [consultationList]);

    const lineConfig = {
        data,
        xField: 'date',
        yField: radioValue,
        xAxis: {
            label: {
                formatter: v => formatDateTimeFromIso(v),
            },
        },
        meta: {
            createdAt: {
                alias: 'Date de la consultation',
                text: 'Date de la consultation',
            },
            heartRate: {
                alias: 'Fréquence cardiaque',
            },
        },
        point: {
            size: 5,
            shape: 'diamond',
            style: {
                fill: 'white',
                stroke: '#5B8FF9',
                lineWidth: 2,
            },
        },
        tooltip: { showMarkers: false },
        state: {
            active: {
                style: {
                    shadowBlur: 4,
                    stroke: '#000',
                    fill: 'red',
                },
            },
        },
        interactions: [{ type: 'marker-active' }],
    };

    return (
        <Spin spinning={contentIsLoading}>
            {consultationList.length ? (
                <div className="flex-column general-info-container consultations-hstory">
                    <Title level={4} style={{ marginBottom: 24, textAlign: 'center' }}>
                        {keys[radioValue]}
                    </Title>

                    <Radio.Group style={{ marginBottom: 24 }}
                        value={radioValue}
                        onChange={(e) => {
                            setRadioValue(e.target.value);
                        }}
                    >
                        <Radio value="heartRate"> Fréquence cardiaque (BPM) </Radio>
                        <Radio value="respiratoryRate"> Fréquence respiratoire (CPM) </Radio>
                        <Radio value="oxygenSaturation"> Sat O2 (%)</Radio>
                        <Radio value="bodyTemperature"> Température (°C) </Radio>
                    </Radio.Group>

                    <Line {...lineConfig} />
                </div>
            ) : (
                <Result
                    status="404"
                    title="Aucune consultation pour ce patient"
                    extra={<Button onClick={() => selectPatient(null)} type="primary">Retour</Button>}
                />
            )}


        </Spin>
    );
};

PatientStats.propTypes = {
    consultationList: PropTypes.array.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    selectPatient: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    consultationList: state.consultations.consultationList,
    contentIsLoading: state.loading.contentIsLoading,
});


const dispatchToProps = dispatch => bindActionCreators(
    {
        selectPatient,
    },
    dispatch,
);


export default connect(stateToProps, dispatchToProps)(PatientStats);
