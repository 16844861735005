import React from 'react';
import { Layout, Menu, Divider } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import items from './config';
import { navigateTo } from '../../history';
import { selectPatient } from '../../redux/patients/actions';
import './styles.css';

const { Item } = Menu;
const { Sider } = Layout;

const Sidebar = ({ selectedItem, selectPatient }) => (
    <Sider className="sidebar" width={145}>
        <Menu
            className="sidebar"
            style={{ marginTop: 14 }}
            selectedKeys={[selectedItem]}
        >
            {items.map(item => (
                <>
                    <Item
                        className="sidebar-menu-item flex-column"
                        key={item.route}
                        icon={item.icon}
                        onClick={() => {
                            navigateTo(item.route);
                            selectPatient(null);
                        }}
                    >
                        {item.name}
                    </Item>
                    <Divider />
                </>
            ))}
        </Menu>
    </Sider>
);

Sidebar.propTypes = {
    selectedItem: PropTypes.string.isRequired,
    selectPatient: PropTypes.func.isRequired,
};

const dispatchToProps = dispatch => bindActionCreators(
    {
        selectPatient,
    },
    dispatch,
);
export default connect(null, dispatchToProps)(Sidebar);
