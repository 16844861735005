import React from 'react';
import {
    Router,
    Switch,
    Route,
} from 'react-router-dom';
import history from './history';
import Dashboard from './sections/Dashboard';
import Patients from './sections/Patients';
import Login from './sections/Login';
import Consultations from './sections/Consultations';
import AdverseEvents from './sections/AdverseEvents';
import Chat from './sections/Chat';


export default function Routes() {
    return (
        <Router history={history}>
            <Switch>
                <Route exact path="/">
                    <Dashboard />
                </Route>
                <Route path="/login">
                    <Login />
                </Route>
                <Route exact path="/patients">
                    <Patients />
                </Route>
                <Route exact path="/consultations">
                    <Consultations />
                </Route>
                <Route exact path="/adverse-events">
                    <AdverseEvents />
                </Route>
                <Route exact path="/messagerie">
                    <Chat />
                </Route>
            </Switch>
        </Router>
    );
}
