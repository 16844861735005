/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Typography, Modal, Form, Input, Radio, Button,
    message, Tree, Timeline,
} from 'antd';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import FORM_VALIDATORS from '../../helpers/formRules';
import { updatePatientAsync } from '../../redux/patients/actions';

const { Title } = Typography;

const AddModal = ({ open, handleClose, handleSubmit }) => {
    const [form] = Form.useForm();

    useEffect(() => {
        if (!open) {
            form.resetFields();
        }
    }, [open]);

    const onOk = async () => {
        try {
            const values = await form.validateFields();
            handleSubmit(values);
        } catch (err) {
            // nvm
        }
    };

    return (
        <Modal title="Ajout d'un test Covid" onCancel={handleClose} onOk={onOk} visible={open}>
            <Form form={form}>
                <Form.Item label="Type" name="type" rules={[FORM_VALIDATORS.required]}>
                    <Radio.Group>
                        <Radio value="PCR"> PCR (24 h)</Radio>
                        <Radio value="Sérologique"> Sérologique (30 minutes) </Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="Date" name="date" rules={[FORM_VALIDATORS.required]}>
                    <Input />
                </Form.Item>
                <Form.Item label="Résultat" name="result" rules={[FORM_VALIDATORS.required]}>
                    <Radio.Group>
                        <Radio value="Positif"> Positif</Radio>
                        <Radio value="Négatif"> Négatif</Radio>
                    </Radio.Group>
                </Form.Item>
            </Form>
        </Modal>
    );
};

AddModal.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
};


const prepareTree = (c, i) => {
    const res = [];
    res.push({
        title: (
            <>
                <b>
                    {'Date : '}
                </b>
                {c.date}
            </>
        ),
        key: `${i}-${c.date}`,
        children: [
            {
                title: (
                    <>
                        <b>
                            {'Type : '}
                        </b>
                        {c.type}
                    </>
                ),
                key: `${i}-${c.type}`,
            },
            {
                title: (
                    <>
                        <b>
                            {'Résultat : '}
                        </b>
                        {c.result}
                    </>
                ),
                key: `${i}-${c.result}`,
            },
        ],
    });
    return res;
};

const CovidTests = ({ selectedPatient, updatePatientAsync }) => {
    const [openAdd, setOpenAdd] = useState();

    const handleAdd = (newTest) => {
        const covidTests = [...selectedPatient.covidTests, newTest];
        updatePatientAsync(selectedPatient._id, { covidTests });
        message.success('Test Covid ajouté avec succès');
        setOpenAdd(false);
    };

    return (
        <div>
            <Button type="primary" className="m-bottom-medium" onClick={() => setOpenAdd(true)}> Ajouter un test</Button>
            <AddModal
                open={openAdd}
                handleClose={() => setOpenAdd(false)}
                handleSubmit={handleAdd}
            />
            <div className="flex-column general-info-container consultations-hstory">
                <Title level={4} style={{ textAlign: 'center' }}>Historique des tests Covid</Title>
                <Timeline>
                    {selectedPatient.covidTests && selectedPatient.covidTests.map((c, i) => (
                        <Timeline.Item key={i.toString()} className="timeline-item-consultations">
                            <Tree treeData={prepareTree(c, i)} />
                        </Timeline.Item>
                    ))

                    }
                </Timeline>


            </div>
        </div>
    );
};

CovidTests.propTypes = {
    selectedPatient: PropTypes.object.isRequired,
    updatePatientAsync: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    selectedPatient: state.patients.selectedPatient,
});

const dispatchToProps = dispatch => bindActionCreators({
    updatePatientAsync,
}, dispatch);

export default connect(stateToProps, dispatchToProps)(CovidTests);
