/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect } from 'react';
import {
    Avatar, List, Input, Typography,
} from 'antd';
import PropTypes from 'prop-types';
import { getFullName, getUserInitials } from '../../helpers/functions';

const { Search } = Input;
const { Title } = Typography;

const filter = (query, data) => {
    const newQuery = query
        .replace(/[^a-z0-9]/gi, '')
        .split('')
        .join('.*');
    return data.filter((item) => {
        const regex = new RegExp(newQuery, 'i');
        return (
            regex.test(item.firstName) || regex.test(item.lastName)
        );
    });
};

const UsersListWithFilter = ({ data, title, onSelect }) => {
    const [currentItems, setCurrentItems] = useState([]);

    const handleSearch = (searchValue) => {
        const filtredData = filter(searchValue, data);
        setCurrentItems(filtredData);
    };


    useEffect(() => {
        if (data.length && !currentItems.length) {
            setCurrentItems(data);
        }
    }, [data]);


    return (
        <div>
            <Title level={4} style={{ textAlign: 'center' }}>
                {title}
            </Title>
            <List
                header={(
                    <Search
                        placeholder="Chercher par nom ou prénom"
                        allowClear
                        // onSearch={handleSearch}
                        onChange={e => handleSearch(e.target.value)}
                    />
                )}
                itemLayout="horizontal"
                dataSource={currentItems}
                renderItem={item => (
                    <List.Item className="nurse-item" key={item._id} onClick={() => onSelect(item)}>
                        <List.Item.Meta
                            avatar={<Avatar style={{ backgroundColor: '#c4313f' }}>{getUserInitials(item)}</Avatar>}
                            title={getFullName(item)}
                        />
                    </List.Item>
                )}
            />
        </div>

    );
};


UsersListWithFilter.propTypes = {
    data: PropTypes.object.isRequired,
    title: PropTypes.string.isRequired,
    onSelect: PropTypes.func.isRequired,
};

export default UsersListWithFilter;
