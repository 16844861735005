import { SET_STATS, SET_PATIENTS } from '../actions';

const initialState = {
    dashboardStats: {
        eventsByStatus: [],
        patientsByGender: [],
        patientsByAge: [],
        patientsByMonth: [],
        patientsByGovernorate: [],
        patientsByStatus: [{ count: 0 }, { count: 0 }, { count: 0 }, { count: 0 }],
        pendingPatientsCount: 0,
    },
    patientsList: [],
};

const stats = (state = initialState, action) => {
    switch (action.type) {
        case SET_STATS:
            return { ...state, dashboardStats: action.payload };
        case SET_PATIENTS:
            return { ...state, patientsList: action.payload };
        default:
            return state;
    }
};

export default stats;
