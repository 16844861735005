/* eslint-disable no-underscore-dangle */
import React, { useState, useEffect, useRef } from 'react';
import { Button, Spin } from 'antd';
import ImageGallery from 'react-image-gallery';
import { RedoOutlined, UndoOutlined } from '@ant-design/icons';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import { loadImage } from '../../api/images';
import Upload from '../upload';
import {
    uploadImageAsync,
    deleteImageAsync,
} from '../../redux/patients/actions';
import 'react-image-gallery/styles/css/image-gallery.css';
import './styles.css';

const parseImage = (filename, category, userId) => ({
    original: loadImage(userId, category, 'preview', filename),
    thumbnail: loadImage(userId, category, 'thumbnail', filename),
    fullscreen: loadImage(userId, category, 'lossless', filename),
});

const ImagesSection = ({
    patient,
    deleteImageAsync,
    uploadImageAsync,
    category,
    contentIsLoading,
}) => {
    const [parsedImages, setParsedImages] = useState([]);
    const [rotationDeg, setRotationDeg] = useState(0);
    const imageGalleryRef = useRef();

    useEffect(() => {
        if (patient) {
            const imgs = [];
            patient[category].map(img => imgs.push(parseImage(img, category, patient._id)));
            setParsedImages(imgs);
        }
    }, [patient]);

    const onDeleteImage = () => {
        if (imageGalleryRef) {
            const { currentIndex } = imageGalleryRef.current.state;
            deleteImageAsync(patient._id, category, patient[category][currentIndex]);
            imageGalleryRef.current.slideToIndex(0);
        }
    };

    const onUploadImage = (file) => {
        uploadImageAsync(patient._id, category, file);
    };

    return (
        <div className="flex" style={{ justifyContent: 'center' }}>
            <Spin spinning={contentIsLoading}>
                <div style={{ marginTop: 50, marginRight: 24, marginLeft: 40 }}>
                    {parsedImages.length ? (
                        <div
                            className="flex"
                            style={{
                                justifyContent: 'space-between',
                                width: '48vw',
                                marginBottom: 14,
                            }}
                        >
                            <Button
                                onClick={() => {
                                    setRotationDeg(rotationDeg > 0 ? rotationDeg - 90 : 270);
                                }}
                                size="large"
                                shape="circle"
                                type="primary"
                                icon={<UndoOutlined />}
                            />
                            <Button
                                onClick={() => {
                                    setRotationDeg(rotationDeg < 270 ? rotationDeg + 90 : 0);
                                }}
                                size="large"
                                shape="circle"
                                type="primary"
                                icon={<RedoOutlined />}
                            />
                        </div>
                    ) : null}
                    <ImageGallery
                        ref={imageGalleryRef}
                        items={parsedImages}
                        showPlayButton={false}
                        showBullets
                        showFullscreenButton={parsedImages.length > 0}
                        additionalClass={`rotate-${rotationDeg}`}
                    />
                    <Upload
                        onDelete={onDeleteImage}
                        onUpload={onUploadImage}
                        showDelete={patient[category].length}
                    />
                </div>
            </Spin>

        </div>
    );
};

ImagesSection.propTypes = {
    deleteImageAsync: PropTypes.func.isRequired,
    uploadImageAsync: PropTypes.func.isRequired,
    patient: PropTypes.object.isRequired,
    category: PropTypes.string.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
};


const stateToProps = state => ({
    contentIsLoading: state.loading.contentIsLoading,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        uploadImageAsync,
        deleteImageAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ImagesSection);
