/* eslint-disable no-underscore-dangle */
import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import PropTypes from 'prop-types';
import {
    Tree, Timeline, Typography, Spin, Result, Button,
} from 'antd';
import prepareTree from '../../helpers/consultations';
import { selectPatient } from '../../redux/patients/actions';

const { Title } = Typography;

const ConsultationsHistory = ({ consultationList, contentIsLoading, selectPatient }) => (
    <Spin spinning={contentIsLoading}>
        <div className="flex-column general-info-container consultations-hstory">
            <Title level={4} style={{ marginBottom: 24, textAlign: 'center' }}>Historique de consultations</Title>
            {consultationList.length ? (
                <Timeline>
                    {consultationList.map(consultation => (
                        <Timeline.Item key={consultation._id} className="timeline-item-consultations">
                            <Tree treeData={prepareTree(consultation)} />
                        </Timeline.Item>
                    ))}
                </Timeline>
            ) : (
                <Result
                    status="404"
                    title="Aucune consultation pour ce patient"
                    extra={<Button onClick={() => selectPatient(null)} type="primary">Retour</Button>}
                />

            )}

        </div>
    </Spin>

);

ConsultationsHistory.propTypes = {
    consultationList: PropTypes.array.isRequired,
    contentIsLoading: PropTypes.bool.isRequired,
    selectPatient: PropTypes.func.isRequired,
};

const stateToProps = state => ({
    consultationList: state.consultations.consultationList,
    contentIsLoading: state.loading.contentIsLoading,
});


const dispatchToProps = dispatch => bindActionCreators(
    {
        selectPatient,
    },
    dispatch,
);


export default connect(stateToProps, dispatchToProps)(ConsultationsHistory);
