/* eslint-disable no-underscore-dangle */
import React from 'react';
import { Typography, Modal } from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setEventsList,
    setFullListAsync,
    createEventAsync,
} from '../../redux/adverseEvents/actions';
import { getFullName, formatDateTimeFromIso } from '../../helpers/functions';

const { Text, Paragraph } = Typography;

const ConsultAdverseEvent = ({ selectedEvent, open, onClose }) => (
    <Modal
        title={`Evénement indésirable du ${formatDateTimeFromIso(
            selectedEvent.createdAt,
        )}`}
        visible={open}
        onCancel={onClose}
        footer={null}
    >
        <div className="flex-column">
            <div className="flex">
                <Text strong style={{ marginRight: 10 }}>
                    {' '}
                    Patient :
                </Text>
                <Text>{getFullName(selectedEvent.patient)}</Text>
            </div>

            <div className="flex">
                <Text strong style={{ marginRight: 10 }}>
                    {' '}
                    Date de survenue :
                </Text>
                <Text>{selectedEvent.date}</Text>
            </div>
            <div className="flex">
                <Text strong style={{ marginRight: 10 }}>
                    Diagnostic :
                </Text>
                <Paragraph>{selectedEvent.diagnosis}</Paragraph>
            </div>
            <div className="flex">
                <Text strong style={{ marginRight: 10 }}>
                    Signes et symptômes :
                </Text>
                <Paragraph>{selectedEvent.signs}</Paragraph>
            </div>
            <div className="flex">
                <Text strong style={{ marginRight: 10 }}>
                    Intensité des symptômes :
                </Text>
                <Text>{selectedEvent.intensity}</Text>
            </div>
            <div className="flex">
                <Text strong style={{ marginRight: 10 }}>
                    Lien de causalité du nouvel évènement :
                </Text>
                <Text>
                    {selectedEvent.extraCausality
                        ? selectedEvent.extraCausality
                        : selectedEvent.causality}
                </Text>
            </div>
            <div className="flex">
                <Text strong style={{ marginRight: 10 }}>
                    Conduite à tenir :
                </Text>
                <Text>{selectedEvent.instructions}</Text>
            </div>
        </div>
    </Modal>
);

ConsultAdverseEvent.propTypes = {
    open: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object,
};

ConsultAdverseEvent.defaultProps = {
    selectedEvent: null,
};

const stateToProps = state => ({
    selectedEvent: state.adverseEvents.selectedEvent,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setEventsList,
        setFullListAsync,
        createEventAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(ConsultAdverseEvent);
