/* eslint-disable no-underscore-dangle */
import React from 'react';
import {
    Form, Radio, Modal, Button,
} from 'antd';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
    setEventsList,
    setFullListAsync,
    createEventAsync,
    updateEventAsync,
} from '../../redux/adverseEvents/actions';
import FORM_VALIDATORS from '../../helpers/formRules';

const UpdateAdverseEvent = ({
    selectedEvent,
    open,
    onClose,
    updateEventAsync,
}) => {
    const onFinish = (values) => {
        updateEventAsync(selectedEvent._id, values);
        onClose();
    };

    return (
        <Modal
            title="Mise a jours d'un événement indésirable"
            visible={open}
            onCancel={onClose}
            footer={null}
        >
            <Form onFinish={onFinish} className="flex-column">
                <Form.Item
                    label="Statut de l'événement"
                    rules={[FORM_VALIDATORS.required]}
                    name="status"
                >
                    <Radio.Group className="flex-column">
                        <Radio value="Résolu avec séquelles">Résolu avec séquelles</Radio>
                        <Radio value="Résolu sans séquelles">Résolu sans séquelles</Radio>
                        <Radio value="En cours de résolution">En cours de résolution</Radio>
                        <Radio value="Fatal">Fatal</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        className="center-button"
                        htmlType="submit"
                        shape="round"
                    >
                        Valider
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

UpdateAdverseEvent.propTypes = {
    open: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    selectedEvent: PropTypes.object,
    updateEventAsync: PropTypes.func.isRequired,
};

UpdateAdverseEvent.defaultProps = {
    selectedEvent: null,
};

const stateToProps = state => ({
    selectedEvent: state.adverseEvents.selectedEvent,
});

const dispatchToProps = dispatch => bindActionCreators(
    {
        setEventsList,
        setFullListAsync,
        createEventAsync,
        updateEventAsync,
    },
    dispatch,
);

export default connect(stateToProps, dispatchToProps)(UpdateAdverseEvent);
