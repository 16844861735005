/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react';
import {
    Form, Radio, Input, InputNumber, Button,
} from 'antd';
import PropTypes from 'prop-types';
import FORM_VALIDATORS from '../../helpers/formRules';
import DatePicker from '../DatePicker';

const showInput = (input, disabled) => {
    switch (input.type) {
        case 'input':
            return <Input disabled={disabled} />;
        case 'date':
            return <DatePicker disabled={disabled} />;

        case 'inputNumber':
            return <InputNumber disabled={disabled} />;
        default:
            return <div />;
    }
};

const AddAntecedent = ({
    radioLabel,
    radioSelection,
    inputs,
    onAdd,
    buttonLabel,
    extraRadioInputs,
}) => {
    const [radioValue, setRadioValue] = useState();
    const [form] = Form.useForm();
    const mapRadioSection = () => radioSelection.map(radioItem => (
        <Radio
            key={radioItem.label}
            value={radioItem.value}
            style={{ margin: 10 }}
        >
            {radioItem.label}
        </Radio>
    ));

    const onFinish = (values) => {
        const antecedent = {
            ...values,
            type: values.type ? values.type : radioValue,
        };

        onAdd(antecedent);
        setRadioValue(null);
        form.resetFields();
    };

    const disabledInputs = radioSelection.length && !radioValue;

    return (
        <Form onFinish={onFinish} layout="vertical" form={form}>
            {radioSelection.length ? (
                <Form.Item label={radioLabel}>
                    <Radio.Group
                        value={radioValue}
                        onChange={e => setRadioValue(e.target.value)}
                    >
                        {mapRadioSection()}
                    </Radio.Group>
                </Form.Item>
            ) : null}

            {radioValue && extraRadioInputs && extraRadioInputs[radioValue] ? (
                <Form.Item
                    label={extraRadioInputs[radioValue].label}
                    name={extraRadioInputs[radioValue].name}
                    rules={[FORM_VALIDATORS.required]}
                >
                    {showInput(extraRadioInputs[radioValue], false)}
                </Form.Item>
            ) : null}

            {inputs.map(input => (
                <Form.Item
                    key={input.label}
                    name={input.name}
                    label={input.label}
                    rules={input.required ? [FORM_VALIDATORS.required] : []}
                >
                    {showInput(input, disabledInputs)}
                </Form.Item>
            ))}
            <Form.Item>
                <Button block className="center-button" style={{ maxWidth: '40%' }} shape="round" type="primary" htmlType="submit">
                    {buttonLabel}
                </Button>
            </Form.Item>
        </Form>
    );
};

AddAntecedent.propTypes = {
    radioSelection: PropTypes.array.isRequired,
    inputs: PropTypes.array.isRequired,
    radioLabel: PropTypes.string.isRequired,
    onAdd: PropTypes.func.isRequired,
    buttonLabel: PropTypes.string.isRequired,
    extraRadioInputs: PropTypes.object,
};

AddAntecedent.defaultProps = {
    extraRadioInputs: {},
};

export default AddAntecedent;
